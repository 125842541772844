import React, { Component } from 'react';
import intl from "react-intl-universal";
import "./css/UnitWear.scss"
import WearData from "./WearData";
class UnitWear extends Component{
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {pos, wear_threshold, wear_data, ecc_degree, ecc_pos, brand, spec, season} = this.props.data;
        const  returnContent = (content) => {
            switch (content) {
                case 'low':
                    return {color: 'green', content: intl.get('usura-l')};
                case 'middle':
                    return {color: '#ffca11', content: intl.get('usura-m')};
                case 'high':
                    return {color: 'red', content: intl.get('usura-h')};
                default:
                    return {color: 'green', content: intl.get('usura-l')};
            }
        };

        const returnImage = (wear_data, degree, ecc_pos) => {
            let lenNum =   wear_data ? wear_data.split(',').length / 4 : 4;
            if (lenNum===1){
                lenNum=4
            }
            return  degree==='low' ? require('../assets/cormach/' + degree + '-'+ lenNum +'.png') : require('../assets/cormach/' + degree + '-'+ ecc_pos + '-' + lenNum +'.png')

        };

       const splitData = (wear_data) => {
        let wear_list = [];
        if(wear_data){
            for (let i = 0; i < wear_data.split(',').length/4; i++) {
              wear_list.push(wear_data.split(',')[4*i+3])
        }
        }else{
            for (let i = 0; i < 4 ; i++) {
                 wear_list.push('')
            }
            }
        return wear_list
        };

       const returnSeason = (content) => {
           switch (content) {
               case 'season_summer':
                   return intl.get('season_summer');
               case 'season_winter':
                   return intl.get('season_winter');
               default:
                   return  '';
           }
       };

        return(
            <div className="unit-wear">
                <div ><span style={{fontWeight: "bold"}}>{pos}:</span><span>{returnSeason(season)}</span></div>
                <div><span>{intl.get('brand')}:</span><span style={{paddingLeft:5}}>{brand}</span></div>
                <div><span>{intl.get('spec')}:</span><span style={{paddingLeft:5}}>{spec}</span></div>
                 <div><span>{intl.get('depth')}:</span><span></span></div>
                 <WearData data={{values: splitData(wear_data), threshold: wear_threshold}}/>
                <div><span>{intl.get('usura')}:</span ><span style={{paddingLeft:5, color: returnContent(ecc_degree).color}}>{returnContent(ecc_degree).content}</span></div>
                <img className="ec-tire" src={returnImage(wear_data, ecc_degree, ecc_pos)} alt=""/>
            </div>
        )
    }
}
export default UnitWear;