import React, {Component} from "react";
import intl from 'react-intl-universal';
import "./css/QuickWear.scss"
import TyrehubTitle from "./TyrehubTitle";
import TyrehubCar from "./TyrehubCar";

class QuickWear extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {lf_degree, rf_degree, lb_degree, rb_degree, model, lf_data, rf_data, lb_data, rb_data, lf_tyre, rf_tyre, lb_tyre, rb_tyre, tyreunit, wear_threshold} = this.props.data;

        const returnList = (data) => {
            let wear_list = [];
            if (data) {
                for (let i = 0; i < data.split(',').length / 4; i++) {
                    let value = data.split(',')[4 * i + 3] / 10;
                    if (tyreunit==='unit_mm') {
                        wear_list.push((value * 1.25984).toFixed(1))
                    } else {
                        wear_list.push(value)
                    }
                }
                return wear_list
            }
        };

        const returnColorFlag = () => {
            const lang = intl.options.currentLocale;
            switch (lang) {
                case 'tw':
                    return require("../assets/tw_wco.png");
                case 'zh':
                    return require("../assets/zh_wco.png");
                case 'en':
                    return require("../assets/en_wco.png");
                case 'ma':
                    return require("../assets/me_wco.png");
                case 'ge':
                    return require("../assets/de_wco.png");
                case 'fr':
                    return require("../assets/frtip.png");
                case 'sp':
                    return require("../assets/sptip.png");
                case 'lt':
                    return require("../assets/lt_wco.png");
                case 'jp':
                    return require("../assets/jp_wco.png");
            }
        };

        const returnWear = (degree) => {
            if (degree === 'low') {
                return {color: 'green', text: intl.get('low')}
            }
            if (degree === 'middle') {
                return {color: '#ffad26', text: intl.get('middle')}
            }
            if (degree === 'high') {
                return {color: 'red', text: intl.get('high')}
            } else {
                return {color: 'green', text: intl.get('low')}
            }

        };
           // 返回阈值
            const returnThreshold = (value, unit) =>{
                if (unit==='unit_in'){
                    return  ((value / 10) * 1.25984).toFixed(1)  + '/32in'
                }else {
                    return value / 10 + 'mm'
                }
            };

        const returnTire = (degree, data) => {
            if (data) {
                let le = data.split(',').length / 4;
                let col = 'green';
                if (degree === 'middle') {
                    col = 'yellow'
                }
                if (degree === 'high') {
                    col = 'red'
                }
                return require("../assets/tyre-" + col + "-" + (5 - le) + ".png");
            } else {
                return require("../assets/tyre-green-1.png");
            }
        };

        return (
            <div className="ty-wear">
                <div><TyrehubTitle content={intl.get('wear')}/></div>
                {/*<div className="ty-color"><img*/}
                {/*    src={model === "nam.tyrescan.ts004" ? require("../assets/namtip.png") : returnColorFlag()} alt=''/>*/}
                {/*</div>*/}

                 <div style={{position: 'relative'}}>
                    <img
                        style={{
                            width: 750,
                            marginTop: 10,
                            height: 40,
                        }}
                        src={require("../assets/wear-lev.png")}
                    />
                    <div  style={{position: 'absolute', top:10, left:50, right:50,display: "flex", justifyContent: "space-between"}}>
                        <div style={{fontSize:14, color:'white', textAlign: 'center'}}>
                            <p>{intl.get('high')}</p>
                            <p>＜{returnThreshold(wear_threshold.low, tyreunit)}</p>
                        </div>
                        <div style={{fontSize:14, color:'white', textAlign: 'center'}}>
                            <p>{intl.get('middle')}</p>
                            <p>{returnThreshold(wear_threshold.low, tyreunit)}-{returnThreshold(wear_threshold.high, tyreunit)}</p>
                        </div>
                        <div style={{fontSize:14, color:'white', textAlign: 'center'}}>
                            <p>{intl.get('low')}</p>
                            <p>≥{returnThreshold(wear_threshold.high, tyreunit)}</p>
                        </div>
                    </div>
                </div>



                <div className="ty-wear-con">
                    <div className="ty-wear-lf">
                        <div className="con-ri">
                            <div><span className="con-le-tit">{intl.get('lf')}:</span><span
                                className="con-le-con"></span></div>
                            <div><img className="con-tire" src={returnTire(lf_degree, lf_data)} alt=""/></div>
                            <div className="con-num">{returnList(lf_data).map((item, index) => (
                                <span key={index}>{item}</span>
                            ))}</div>
                            <div><span className="con-le-tit">{intl.get('abrasion')}:</span><span
                                className='con-le-con'
                                style={{color: returnWear(lf_degree).color}}>{returnWear(lf_degree).text}</span></div>
                        </div>
                    </div>
                    <div className="ty-wear-rf">
                        <div className="con-ri">
                            <div><span className="con-le-tit">{intl.get('rf')}:</span><span
                                className="con-le-con"></span></div>
                            <div><img className="con-tire" src={returnTire(rf_degree, rf_data)} alt=""/></div>
                            <div className="con-num">{returnList(rf_data).map((item, index) => (
                                <span key={index}>{item}</span>
                            ))}</div>
                            <div><span className="con-le-tit">{intl.get('abrasion')}:</span><span
                                className='con-le-con'
                                style={{color: returnWear(rf_degree).color}}>{returnWear(rf_degree).text}</span></div>
                        </div>

                    </div>
                    <div className="ty-wear-cen">
                        <TyrehubCar data={this.props.data}/>
                    </div>
                    <div className="ty-wear-lb">
                        <div className="con-ri">
                            <div><span className="con-le-tit">{intl.get('lr')}:</span><span className="con-le-con"></span></div>
                            <div><img className="con-tire" src={returnTire(lb_degree, lb_data)} alt=""/></div>
                            <div className="con-num">{returnList(lb_data).map((item, index) => (
                                <span key={index}>{item}</span>
                            ))}</div>
                            <div><span className="con-le-tit">{intl.get('abrasion')}:</span><span
                                className='con-le-con'
                                style={{color: returnWear(lb_degree).color}}>{returnWear(lb_degree).text}</span></div>
                        </div>
                    </div>
                    <div className="ty-wear-rb">
                        <div className="con-ri">
                            <div><span className="con-le-tit">{intl.get('rr')}:</span><span className="con-le-con"></span></div>
                            <div><img className="con-tire" src={returnTire(rb_degree, rb_data)} alt=""/></div>
                            <div className="con-num">{returnList(rb_data).map((item, index) => (
                                <span key={index}>{item}</span>
                            ))}</div>
                            <div><span className="con-le-tit">{intl.get('abrasion')}:</span><span
                                className='con-le-con'
                                style={{color: returnWear(rb_degree).color}}>{returnWear(rb_degree).text}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default QuickWear;