import React, {Component} from "react";
import Corm from "./Corm";
import Tyrehub from "./tyrehub/Tyrehub";
import DetailNew from "./DetailNew";

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            report: null,
        }
    };

    componentDidMount() {
        this.getRecordDetail();
    }

    // 获取检测报告详情
    getRecordDetail() {
        global.Api.getReportsDetail(this.props.match.params.id).then(res => {
            if (res.data.code === 0) {
                this.setState({
                    report: res.data.data.report
                });
            } else {
                console.log(res.data.message);
            }
        });
    }


    render() {
        if (this.state.report) {
            let {
                model_number,
            recordtype} = this.state.report;
            const returnComponent = (model, data) => {
                switch (model) {
                    case 'cormach.tyrescan.ts004':
                        return <Corm uuid={data.uuid}/>;
                    case 'nam.tyrescan.ts004':
                        return <Tyrehub data={{reportData: data}}/>;
                    default:
                        if(recordtype === 'quickscan'){
                             return <Tyrehub data={{reportData: data}}/>
                        }else {
                            return <DetailNew data={data}/>
                        }
                }

            };

            return (
                <div>
                    {returnComponent(model_number, this.state.report)}
                </div>
            )

        } else {
            return (<div></div>)
        }

    }

}

export default Detail;