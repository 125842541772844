import React, {Component} from "react";
import "../css/Lorry/DoubleTyre.scss"
import intl from "react-intl-universal";

class DoubleTyre extends Component{
     constructor(props) {
         super(props);
         this.state = {}
     };

     render() {
         let {lfw, lf, lb, lbw} = this.props.data;
        let returnColor = (degree) => {
            switch (degree) {
                case 'high':
                    return {url: require("../../assets/lorry/r-c.png"), color: "#FF5230", text: intl.get('high')};
                case 'low' || 'middle':
                    return {url: require("../../assets/lorry/y-c.png"), color: "#36B37E", text: intl.get('low')};
                default:
                    return {url: require("../../assets/lorry/hui-c.png"), color: "#c2c2c2", text: intl.get('undevice')};
            }

        };
        let lang = intl.options.currentLocale;
         return (
             <div className="dt">
                <span className="ts-title">{lf.position.slice(1,2)}{intl.get('axle')}</span>
                <img src={require("../../assets/lorry/axle-2l.png")} alt="" className="ts-ax"/>
                <img src={returnColor(lbw.degree).url} alt="" className="ts-ri"/>
                <img src={returnColor(lfw.degree).url} alt=""  className="ts-le"/>
                <img src={returnColor(lb.degree).url} alt="" className="ts-rin"/>
                <img src={returnColor(lf.degree).url} alt=""  className="ts-len"/>
                <span className="ts-num-ri">{lang != 'zh'?lbw.en_num:lbw.number}</span>
                <span className="ts-num-le">{lang != 'zh'?lfw.en_num:lfw.number}</span>
                   <span className="ts-num-n-ri">{lang != 'zh'?lb.en_num:lb.number}</span>
                <span className="ts-num-n-le">{lang != 'zh'?lf.en_num:lf.number}</span>
             </div>
         )
     }
}

export default DoubleTyre;