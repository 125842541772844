export default {
    'login': 'Log on',
    'input_name': 'Digitate il nome dell’utente',
    'input_password': 'Digitare la parola d’ordine',
    'login_su': 'Il successo del log on',
    'role': 'ruolo',
    'username': 'nome utente',
    'password': 'password',

    'store': 'Denominazione di vendita',
    'number': 'Numero',
    'device_time': 'Tempo di rilevamento',
    'wear_co': 'L’usura delle',
    'ecc_co': 'Situazione di macinazione',
    'plate_no': 'Numero di targa',
    'cus_ph': 'I cellulari.',
    'car_type': 'Modello del veicolo',
    'operation': 'Operazioni',
    'view': 'Dettagli',
    'cus_email': 'Indirizzo e-mail del cliente',
    'cus_name': 'Nome del cliente',


    'print': 'stampa',
    'store_name': 'Negozio di servizi',
    'address': 'Indirizzo di vendita',
    'model': 'Modello di veicolo',
    'mileage': 'Chilometraggio',
    'device': 'Rilevamento del dispositivo',
    'remark': 'Commento',
    'purpose': 'Scopo dell\'ispezione',
    're': 'Consigli per il rilevamento',
    'color_flag': 'Colore logo',
    'wear': 'Usura generale',
    'brake': 'Promemoria dello spazio di frenata attuale',
    'ecc': 'Usura irregolare',
    'lf': 'L-F',
    'lr': 'L-R',
    'rf': 'R-F',
    'rr': 'R-R',
    'untest': 'Pneumatici non esaminati',
    'low': 'Buono stato',
    'middle': 'Stato limite',
    'high': 'Sostituzione',
    'avg': 'Valore medio',
    'spec': "Specifiche",
    'brand': 'Marchio',
    'ecc_avg': 'Usura uniforme del motivo',
    'trauma': 'Danneggiamento del pneumatico',
    'zc': 'normale',
    'image': 'zh',
    'qr': 'Pulisce il rapporto',
    'model_number': 'HH Tire Tread Depth Detector',
    'purp_wash': 'Autolavaggio',
    'purp_alignment': 'Allineamento a quattro ruote',
    'purp_replace': 'Cambia il pneumatico',
    'purp_maintain': 'Manutenzione e riparazione',
    'warn': 'Avvertenza: a seconda delle condizioni attuali di usura dei pneumatici, la distanza tra i freni può aumentare',
    'trauma_normal': 'Normale',
    'trauma_bulge': 'Rigonfiamento',
    'trauma_aging': 'Invecchiamento',

    'txt_lf_outworn': 'L\'esterno del pneumatico anteriore sinistro è più usurato rispetto all\'interno',
    'txt_lf_innerworn': 'L\'interno del pneumatico anteriore sinistro è più usurato rispetto all\'esterno',
    'txt_lf_bothsideworn': 'Entrambi i lati del pneumatico anteriore sinistro sono più consumati rispetto al centro',
    'txt_lf_centreworn': 'Il centro del pneumatico anteriore sinistro è più usurato rispetto a entrambi i lati',
    'txt_lb_outworn': 'L\'esterno del pneumatico posteriore sinistro è più usurato rispetto all\'interno',
    'txt_lb_innerworn': 'L\'interno del pneumatico posteriore sinistro è più usurato rispetto all\'esterno',
    'txt_lb_bothsideworn': 'Entrambi i lati del pneumatico posteriore sinistro sono più consumati rispetto al centro',
    'txt_lb_centreworn': 'Il centro del pneumatico posteriore sinistro è più usurato rispetto a entrambi i lati',
    'txt_rb_outworn': 'L\'esterno del pneumatico posteriore destro è più usurato rispetto all\'interno',
    'txt_rb_innerworn': 'L\'interno del pneumatico posteriore destro è più usurato rispetto all\'esterno"',
    'txt_rb_bothsideworn': 'Entrambi i lati del pneumatico posteriore destro sono più consumati rispetto al centro',
    'txt_rb_centreworn': 'Il centro del pneumatico posteriore destro è più usurato rispetto a entrambi i lati"',
    'txt_rf_outworn': 'L\'esterno del pneumatico anteriore destro è più usurato rispetto all\'interno',
    'txt_rf_innerworn': 'L\'interno del pneumatico anteriore destro è più usurato rispetto all\'esterno',
    'txt_rf_bothsideworn': 'Entrambi i lati del pneumatico anteriore destro sono più consumati rispetto al centro"',
    'txt_rf_centreworn': 'Il centro del pneumatico anteriore destro è più usurato rispetto a entrambi i lati"',

    'txt_tire_need_replace': 'Usura parziale, i pneumatici devono essere sostituiti immediatamente',
    'txt_tire_boundary': 'Usura parziale, è già nello stato limite',
    'txt_alignment_chassis': 'Si consiglia di eseguire ulteriori allineamento a quattro ruote o rilevamento del telaio.',
    'txt_wear_uniform': 'L\'usura dei pneumatici singoli è più uniforme',
    'txt_do_further_check': 'Per favore rileva ulteriormente"',
    'txt_uneven_check': 'Usura eccessiva e si consiglia un ulteriore rilevamento',
    'txt_tire_rotation': 'Trasposizione dei pneumatici raccomandata',
    'txt_wear_normal': 'Usura normale dei pneumatici',
    'txt_tire': 'Pneumatici',

    'time': 'Scegliere il momento',
    'today': 'Oggi',
    'yesterday': 'Ieri',
    'week': 'Questa settimana',
    'month': 'Questo mese',
    'last_month': 'Mese scorso',
    'year': 'Quest\'anno',
    'language': 'Scelta delle lingue',
    'zh': 'In cinese',
    'en': 'Regno unito',
    'ma': 'Malay',
    'ge': 'Il tedesco',
    'fr': 'Francese',
    'lt': 'Italiano',
    'sp': 'Lo spagnolo',
    'tw': 'Cinese vivo',
    'jp': 'Giapponese',
    'po': 'Polonia',
    'por': 'Portoghese',
    'download': 'Scaricare',
    'advise': 'Si raccomanda la presenza di molature parziali per i pneumatici e si raccomandano ulteriori controlli',


    'txt_tire_age_5': 'Esistono pneumatici usati da più di cinque anni e si propone un controllo completo;',
    'txt_tire_age_3': 'Esistono pneumatici usati da più di tre anni e si propone un controllo completo;',
    'txt_tire_age_ok': 'Numero anni;',
    'txt_tire_trauma_check': 'Il rivestimento dei pneumatici presenta un trauma e si raccomanda di effettuare ulteriori controlli;',
    'txt_install_abnormal_check': 'Si raccomanda di effettuare ulteriori controlli;',
    'txt_tire_appearance_ok': 'Buono stato;',
    'txt_pinnate_check': 'C\'è l\'usura della piuma sul pneumatico. Si raccomanda di controllare ulteriormente le parti del telai.',
    'trauma_lacerate': 'Lacerato',
    'trauma_dropblock': 'Blocco di goccia',
    'trauma_puncture': 'piercing',
    'trauma_scratch': 'Contusioni',
    'trauma_pinnate': 'Abbigliamento di pino',
    'txt_tire_age': 'Età del pneumatico',
    'txt_tire appearance': 'Aspetto del pneumatico',
    'tire_installed': 'L’installazione di pneumatici',
    'abnormal': 'Invertire dentro e fuori',
    'endurance': 'Stima del chilometraggio percorso dai pneumatici',
    'endurance-warn': 'Residuo teorico è {number}km',
    'endurance-zero': ' Il pneumatico ha raggiunto il limite, si prega di sostituirlo immediatamente',
    'estimated_value': 'Valore stimato',
    'abrasion': 'Usura',
    'remanent': 'Mancano {days} giorni',
    'useful_life': 'Stima degli anni di utilizzo dei pneumatici',
    'dot_explain': 'DOT：Tempo di fabbricazione dei pneumatici',
    'unit_km': 'Unità：km',
    'unit_year': 'Unità：anno',
    'dot_no': 'Tempo di produzione non ottenuto',
    'made_time': 'Tempo di produzione: {year}({year} anno {week} settimana)',
    'exceed_five': 'Sostituisci immediatamente',
    'inspector': 'Tecnico',
    'txt_install_check': 'Controllo dell\'installazione',
    'txt_install_ok': 'normale',
    'txt_pinnate_wear': 'Abbigliamento di pino',


    'LFtxt_tire_need_replace': '[L-F]Usura parziale, i pneumatici devono essere sostituiti immediatamente',
    'RFtxt_tire_need_replace': '[R-F]Usura parziale, i pneumatici devono essere sostituiti immediatamente',
    'LBtxt_tire_need_replace': '[L-R]Usura parziale, i pneumatici devono essere sostituiti immediatamente',
    'RBtxt_tire_need_replace': '[R-R]Usura parziale, i pneumatici devono essere sostituiti immediatamente',
    'FFtxt_tire_need_replace': '[F-F]Usura parziale, i pneumatici devono essere sostituiti immediatamente',
    'BBtxt_tire_need_replace': '[B-B]Usura parziale, i pneumatici devono essere sostituiti immediatamente',


    'txt_pattern_wear': 'Usura anormale',
    'txt_uneven_center': 'Abbigliamento anormale al centro della ruota. Si raccomanda di controllare la pressione degli pneumatici',
    'LFtxt_uneven_center': '[L-F]Abbigliamento anormale al centro della ruota. Si raccomanda di controllare la pressione degli pneumatici',
    'RFtxt_uneven_center': '[R-F]Abbigliamento anormale al centro della ruota. Si raccomanda di controllare la pressione degli pneumatici',
    'LBtxt_uneven_center': '[L-R]Abbigliamento anormale al centro della ruota. Si raccomanda di controllare la pressione degli pneumatici',
    'RBtxt_uneven_center': '[R-R]Abbigliamento anormale al centro della ruota. Si raccomanda di controllare la pressione degli pneumatici',
    'FFtxt_uneven_center': '[F-F]Abbigliamento anormale al centro della ruota. Si raccomanda di controllare la pressione degli pneumatici',
    'BBtxt_uneven_center': '[B-B]Abbigliamento anormale al centro della ruota. Si raccomanda di controllare la pressione degli pneumatici',

    'txt_uneven_out': 'Il lato esterno del pneumatico è indossato in modo anormale. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici',
    'LFtxt_uneven_out': '[L-F]Il lato esterno del pneumatico è indossato in modo anormale. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici',
    'RFtxt_uneven_out': '[R-F]Il lato esterno del pneumatico è indossato in modo anormale. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici',
    'LBtxt_uneven_out': '[L-R]Il lato esterno del pneumatico è indossato in modo anormale. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici',
    'RBtxt_uneven_out': '[R-R]Il lato esterno del pneumatico è indossato in modo anormale. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici',
    'FFtxt_uneven_out': '[F-F]Il lato esterno del pneumatico è indossato in modo anormale. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici',
    'BBtxt_uneven_out': '[B-B]Il lato esterno del pneumatico è indossato in modo anormale. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici',

    'LFtxt_pinnate_check': '[L-F]C\'è l\'usura della piuma sul pneumatico. Si raccomanda di controllare ulteriormente le parti del telai.',
    'RFtxt_pinnate_check': '[R-F]C\'è l\'usura della piuma sul pneumatico. Si raccomanda di controllare ulteriormente le parti del telai.',
    'LBtxt_pinnate_check': '[L-R]C\'è l\'usura della piuma sul pneumatico. Si raccomanda di controllare ulteriormente le parti del telai.',
    'RBtxt_pinnate_check': '[R-R]C\'è l\'usura della piuma sul pneumatico. Si raccomanda di controllare ulteriormente le parti del telai.',
    'FFtxt_pinnate_check': '[F-F]C\'è l\'usura della piuma sul pneumatico. Si raccomanda di controllare ulteriormente le parti del telai.',
    'BBtxt_pinnate_check': '[B-B]C\'è l\'usura della piuma sul pneumatico. Si raccomanda di controllare ulteriormente le parti del telai.',

    'txt_uneven_inside': 'Il lato interno del pneumatico è indossato in modo anormale. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici',
    'LFtxt_uneven_inside': '[L-F]Il lato interno del pneumatico è indossato in modo anormale. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici',
    'RFtxt_uneven_inside': '[R-F]Il lato interno del pneumatico è indossato in modo anormale. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici',
    'LBtxt_uneven_inside': '[L-R]Il lato interno del pneumatico è indossato in modo anormale. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici',
    'RBtxt_uneven_inside': '[R-R]Il lato interno del pneumatico è indossato in modo anormale. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici',
    'FFtxt_uneven_inside': '[F-F]Il lato interno del pneumatico è indossato in modo anormale. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici',
    'BBtxt_uneven_inside': '[B-B]Il lato interno del pneumatico è indossato in modo anormale. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici',

    'FFtxt_uneven_check': '[F-F]Usura eccessiva e si consiglia un ulteriore rilevamento',
    'BBtxt_uneven_check': '[B-B]Usura eccessiva e si consiglia un ulteriore rilevamento',


    'LFtxt_tire_age_5': '[L-F]Esistono pneumatici usati da più di cinque anni e si propone un controllo completo.',
    'RFtxt_tire_age_5': '[R-F]Esistono pneumatici usati da più di cinque anni e si propone un controllo completo.',
    'LBtxt_tire_age_5': '[L-R]Esistono pneumatici usati da più di cinque anni e si propone un controllo completo.',
    'RBtxt_tire_age_5': '[R-R]Esistono pneumatici usati da più di cinque anni e si propone un controllo completo.',
    'FFtxt_tire_age_5': '[F-F]Esistono pneumatici usati da più di cinque anni e si propone un controllo completo.',
    'BBtxt_tire_age_5': '[B-B]Esistono pneumatici usati da più di cinque anni e si propone un controllo completo.',

    'txt_appearance_check': 'L\'aspetto del pneumatico è anomalo, si raccomanda un\'ulteriore ispezione',
    'LFtxt_appearance_check': '[L-F]L\'aspetto del pneumatico è anomalo, si raccomanda un\'ulteriore ispezione',
    'RFtxt_appearance_check': '[R-F]L\'aspetto del pneumatico è anomalo, si raccomanda un\'ulteriore ispezione',
    'LBtxt_appearance_check': '[L-R]L\'aspetto del pneumatico è anomalo, si raccomanda un\'ulteriore ispezione',
    'RBtxt_appearance_check': '[R-R]L\'aspetto del pneumatico è anomalo, si raccomanda un\'ulteriore ispezione',
    'FFtxt_appearance_check': '[F-F]L\'aspetto del pneumatico è anomalo, si raccomanda un\'ulteriore ispezione',
    'BBtxt_appearance_check': '[B-B]L\'aspetto del pneumatico è anomalo, si raccomanda un\'ulteriore ispezione',


    'LFtxt_install_abnormal_check': '[L-F]Si raccomanda di effettuare ulteriori controlli',
    'RFtxt_install_abnormal_check': '[R-F]Si raccomanda di effettuare ulteriori controlli',
    'LBtxt_install_abnormal_check': '[L-R]Si raccomanda di effettuare ulteriori controlli',
    'RBtxt_install_abnormal_check': '[R-R]Si raccomanda di effettuare ulteriori controlli',
    'FFtxt_install_abnormal_check': '[F-F]Si raccomanda di effettuare ulteriori controlli',
    'BBtxt_install_abnormal_check': '[B-B]Si raccomanda di effettuare ulteriori controlli',

    'txt_uneven_bothside': 'Abbigliamento anormale su entrambi i lati della ruota. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici.',
    'LFtxt_uneven_bothside': '[L-F]Abbigliamento anormale su entrambi i lati della ruota. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici.',
    'RFtxt_uneven_bothside': '[R-F]Abbigliamento anormale su entrambi i lati della ruota. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici.',
    'LBtxt_uneven_bothside': '[L-R]Abbigliamento anormale su entrambi i lati della ruota. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici.',
    'RBtxt_uneven_bothside': '[R-R]Abbigliamento anormale su entrambi i lati della ruota. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici.',
    'FFtxt_uneven_bothside': '[F-F]Abbigliamento anormale su entrambi i lati della ruota. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici.',
    'BBtxt_uneven_bothside': '[B-B]Abbigliamento anormale su entrambi i lati della ruota. Si raccomanda di controllare l\'allineamento delle quattro ruote e la pressione dei pneumatici.',


    'txt_wear_lookup': 'Per favore, controlla l\'usura dei pneumatici',
    'LFtxt_wear_lookup': '[L-F]Per favore, controlla l\'usura dei pneumatici',
    'RFtxt_wear_lookup': '[R-F]Per favore, controlla l\'usura dei pneumatici',
    'LBtxt_wear_lookup': '[L-B]Per favore, controlla l\'usura dei pneumatici',
    'RBtxt_wear_lookup': '[R-B]Per favore, controlla l\'usura dei pneumatici',
    'FFtxt_wear_lookup': '[F-F]Per favore, controlla l\'usura dei pneumatici',
    'BBtxt_wear_lookup': '[B-B]Per favore, controlla l\'usura dei pneumatici',

    'txt_appearance_lookup': 'Per favore controlla l\'aspetto dei pneumatici',
    'LFtxt_appearance_lookup': '[L-F]Per favore controlla l\'aspetto dei pneumatici',
    'RFtxt_appearance_lookup': '[R-F]Per favore controlla l\'aspetto dei pneumatici',
    'LBtxt_appearance_lookup': '[L-B]Per favore controlla l\'aspetto dei pneumatici',
    'RBtxt_appearance_lookup': '[R-B]Per favore controlla l\'aspetto dei pneumatici',
    'FFtxt_appearance_lookup': '[F-F]Per favore controlla l\'aspetto dei pneumatici',
    'BBtxt_appearance_lookup': '[B-B]Per favore controlla l\'aspetto dei pneumatici',

    'txt_serious_trauma_check': 'Il pneumatico è gravemente danneggiato. Si raccomanda di sostituire il pneumatico',
    'LFtxt_serious_trauma_check': '[L-F]Il pneumatico è gravemente danneggiato. Si raccomanda di sostituire il pneumatico',
    'RFtxt_serious_trauma_check': '[R-F]Il pneumatico è gravemente danneggiato. Si raccomanda di sostituire il pneumatico',
    'LBtxt_serious_trauma_check': '[L-B]Il pneumatico è gravemente danneggiato. Si raccomanda di sostituire il pneumatico',
    'RBtxt_serious_trauma_check': '[R-B]Il pneumatico è gravemente danneggiato. Si raccomanda di sostituire il pneumatico',
    'FFtxt_serious_trauma_check': '[F-F]Il pneumatico è gravemente danneggiato. Si raccomanda di sostituire il pneumatico',
    'BBtxt_serious_trauma_check': '[B-B]Il pneumatico è gravemente danneggiato. Si raccomanda di sostituire il pneumatico',

    'purp_tireservice': 'Servizio di pneumatici',
    'purp_vehicle_inspection': 'Inspección de vehículos',
    'merchant': 'Mercanti',
    'manager': 'Negoziante',
    'assistant': 'Impiegato',

    'txt_chassis_check': 'Ispezione di telaio',
    'txt_chassis_ok': 'Il telaio è in buone condizioni',
    'LFtxt_chassis_ok': '[L-F]Il telaio è in buone condizioni',
    'RFtxt_chassis_ok': '[R-F]Il telaio è in buone condizioni',
    'LBtxt_chassis_ok': '[L-B]Il telaio è in buone condizioni',
    'RBtxt_chassis_ok': '[R-R]Il telaio è in buone condizioni',
    'FFtxt_chassis_ok': '[F-F]Il telaio è in buone condizioni',
    'BBtxt_chassis_ok': '[B-B]Il telaio è in buone condizioni',
    'txt_chassis_oil': 'L\'ammortizzatore ha perdita di olio. Si raccomanda di effettuare ulteriori conferme e di sostituirlo se necessario.',
    'LFtxt_chassis_oil': '[L-F]L\'ammortizzatore ha perdita di olio. Si raccomanda di effettuare ulteriori conferme e di sostituirlo se necessario.',
    'RFtxt_chassis_oil': '[R-F]L\'ammortizzatore ha perdita di olio. Si raccomanda di effettuare ulteriori conferme e di sostituirlo se necessario.',
    'LBtxt_chassis_oil': '[L-B]L\'ammortizzatore ha perdita di olio. Si raccomanda di effettuare ulteriori conferme e di sostituirlo se necessario.',
    'RBtxt_chassis_oil': '[R-R]L\'ammortizzatore ha perdita di olio. Si raccomanda di effettuare ulteriori conferme e di sostituirlo se necessario.',
    'FFtxt_chassis_oil': '[F-F]L\'ammortizzatore ha perdita di olio. Si raccomanda di effettuare ulteriori conferme e di sostituirlo se necessario.',
    'BBtxt_chassis_oil': '[B-B]L\'ammortizzatore ha perdita di olio. Si raccomanda di effettuare ulteriori conferme e di sostituirlo se necessario.',
    'txt_chassis_aging': 'La gomma della testa dell\'albero sta invecchiando, quindi si raccomanda di sostituirla',
    'LFtxt_chassis_aging': '[L-F]La gomma della testa dell\'albero sta invecchiando, quindi si raccomanda di sostituirla',
    'RFtxt_chassis_aging': '[R-F]La gomma della testa dell\'albero sta invecchiando, quindi si raccomanda di sostituirla',
    'LBtxt_chassis_aging': '[L-B]La gomma della testa dell\'albero sta invecchiando, quindi si raccomanda di sostituirla',
    'RBtxt_chassis_aging': '[R-R]La gomma della testa dell\'albero sta invecchiando, quindi si raccomanda di sostituirla',
    'FFtxt_chassis_aging': '[F-F]La gomma della testa dell\'albero sta invecchiando, quindi si raccomanda di sostituirla',
    'BBtxt_chassis_aging': '[B-B]La gomma della testa dell\'albero sta invecchiando, quindi si raccomanda di sostituirla',
    'awd': 'App Download',

    'depth': 'Profondità della scanalatura',
    'usura': 'Usura parziale',
    'usura-l': 'Usura uniforme del motivo',
    'usura-m': 'Leggera usura parziale',
    'usura-h': 'Usura uniforme del motivo',

    'engine-hood': 'Cofano motore',
    'change-control': 'Alteri Controlli',
    'subtotal': 'Sottoscocca',

    'replace': 'Sostituire',
    'vedi': 'Vedi Note',
    'ok': 'OK',

    'liquid': 'LIQUIDO LAVAVETRI',
    'level-control': 'Controllo livello',
    'air': 'CLIMATIZZATORE',
    'last-reload': 'Ultima ricarica',
    'absorber': 'AMMORTIZZATORI',
    'check-loss': 'Verifica perdite visibill',
    'fluid': 'FLUIDO RADIATORE',
    'lighting': 'FANALERIA',
    'verification': 'Verifica  funzionamento',
    'disc': 'DISCHI FRENO',
    'condition': 'Stato di usura',
    'oil': 'OLIO MOTORE',
    'wiper': 'TERGICRISTALLI',
    'pads': 'PASTIGLIE FRENO',
    'brake-fluid': 'LIQUIDO FRENI',
    'quality-control': 'Controllo qualità',
    'battery': 'BATTERIA',
    'multimetro': 'Test multimetro',
    'control-quality': 'VERIFICA FUNZIONAMENTO',
    'tightening': 'serraggio con dinamometrica effettuato da',
    'buone': 'Buone Notizie',
    'lavoro': 'Programmazione Lavoro',
    'concordare': 'concordare lavoro immediato',
    'season_summer': 'PNEUMATICO ESTIVO',
    'season_winter': 'PNEUMATICO INVERNALE',

    'com': 'Commercial',
    'pass': 'Passenger',


}
