import React, { Component } from 'react';
import intl from "react-intl-universal";
import "./css/CheckItem.scss"
class CheckItem extends Component{
      constructor(props) {
        super(props);
        this.state ={}
    }

    render() {
        let {title, note1, degree} = this.props.data;
        let returnColor = (degree) =>{
              if (degree=="high"){
                  return {color:{backgroundColor:'red'}, note: intl.get('replace')}
              }
            if (degree=="middle"){
                  return {color:{backgroundColor:'yellow'}, note: intl.get('vedi')}
              }
            else {
                return {color:{backgroundColor:'green'}, note: intl.get('ok')}
            }

    }
          return (
              <div className="it">
                  <div className="it-left">
                      <p className="ttle">{title}</p>
                      <p className="note"> {note1}</p>
                  </div>
                  <div  className="it-right">
                      <div style={{width: 70, margin: "auto"}}><p id="dot" style={returnColor(degree).color}></p></div>
                      <p className="note" style={{width: 70, textAlign:"center"}}>{returnColor(degree).note}</p>
                  </div>
              </div>
          )
    }

}

export default CheckItem;