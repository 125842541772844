import React, { Component } from 'react';
import { Card, Form, Icon, Input, Button, message, Select} from 'antd';
import './Login.scss';
import Cookies from 'universal-cookie';
import intl from 'react-intl-universal';
const cookies = new Cookies();
const { Option } = Select;
class Login extends Component {
  constructor(props){
    super();
    this.submit = this.submit.bind(this);
  }

  handleChange = (value) => {
  value = Number(value);
  return value
}

  submit(e){
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        global.Api.login(values).then(res=>{
          if(res.data.code === 0){
            cookies.set('tyrehub-pc',res.data.data.token)
            message.success(intl.get('login_su')+"!");
            this.props.history.push('/Reports')
            return
          }
          message.error(res.data.message);
        })
      }
    });
  }
  downloadApp(){
    global.Api.getApp().then(res=>{
          if(res.data.code === 0){
            let url = res.data.data.url;
            window.open(url);
          }else {
             message.error(res.data.message);
          }
        })
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="login">
        <Button type="primary" style={{position:"absolute",left:"20px",top:"18px"}} onClick={this.downloadApp}>{intl.get('awd')}</Button>
        <Card title={intl.get('login')} style={{ width: 500 }}>
        <Form onSubmit={this.submit}>
          {/*<Form.Item>*/}
          {/*  {*/}
          {/*    getFieldDecorator('role',{ rules:[{ required: true }]})(*/}
          {/*         <Select   onChange={this.handleChange} placeholder={intl.get('role')} >*/}
          {/*         <Option   value= "1">{intl.get('merchant')}</Option>*/}
          {/*         <Option  value= "2">{intl.get('manager')}</Option>*/}
          {/*         <Option  value= "3">{intl.get('assistant')}</Option>*/}
          {/*  </Select>*/}
          {/*    )*/}
          {/*  }*/}
          {/*</Form.Item>*/}
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: intl.get('input_name') }],
            })(
              <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={intl.get('username')} />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: intl.get('input_password') }],
            })(
              <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder={intl.get('password')} />
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{width:'100%'}}>
            {intl.get('login')}
            </Button>
          </Form.Item>
        </Form>
        </Card>
      </div>
    );
  }
}

export default Form.create()(Login);
