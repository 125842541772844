export default {
    'login': 'Login',
    'input_name': 'Por favor, introduzca su nombre de usuario',
    'input_password': 'Por favor, introduzca su contraseña',
    'login_su': 'Inicio de sesión exitoso',
    'role':'Papel',
    'username':'Nombre de usuario',
    'password':'Contraseña',

    'store': 'Nombre de la tienda',
    'number': 'Numerar',
    'device_time': 'El tiempo de detección',
    'wear_co': 'El desgaste',
    'ecc_co': 'Condiciones de desvío',
    'plate_no': 'Placa de matrícula',
    'cus_ph': 'Número de teléfono móvil',
    'car_type': 'Tipo de vehículo',
    'operation': 'maniobra',
    'view': 'detalle',
    'cus_email': 'Buzón de correo',
    'cus_name': 'Nombre del cliente',


    'print': 'Imprimir',
    'store_name': 'Tienda',
    'address': 'Dirección',
    'model': 'Tipo de vehiculo',
    'mileage': 'Kilometraje',
    'device': 'Equipo de detección',
    'remark': 'Comentarios',
    'purpose': 'Propósito',
    're': 'Recomendaciones de inspección',
    'color_flag': 'Identificación en color',
    'wear': 'Desgaste general',
    'brake': 'Recordatorio actual de distancia de frenado.',
    'ecc': 'Estado de deformación',
    'lf': 'L-F',
    'lr': 'L-R',
    'rf': 'R-F',
    'rr': 'R-R',
    'untest': 'Neumáticos no detectados',
    'low': 'Normal',
    'middle': 'Perímetro',
    'high': 'Reemplazar',
    'avg': 'Promedio',
    'spec': "Tamaño",
    'brand': 'Marca',
    'ecc_avg': 'Desgaste uniforme',
    'trauma': 'Trauma del neumático',
    'zc': 'Normal',
    'image': 'zh',
    'qr': 'Código de escaneo para ver el informe',
    'model_number': 'HH Tire Tread Depth Detector',
    'purp_wash': 'Lavado de autos y belleza',
    'purp_alignment': 'Reemplazo de llanta',
    'purp_replace': 'Reemplazo de llanta',
    'purp_maintain': 'Mantenimiento',
    'warn': 'advertencia：Según el desgaste de los neumáticos, la distancia de frenado se incrementa',
    'trauma_normal': 'Normal',
    'trauma_bulge': 'Bulto',
    'trauma_aging': 'Envejecimiento',

    'txt_lf_outworn': 'El exterior del neumático delantero izquierdo está más desgastado que el interior.',
    'txt_lf_innerworn': 'El interior del neumático delantero izquierdo está más desgastado que el exterior',
    'txt_lf_bothsideworn': 'Ambos lados del neumático delantero izquierdo están más desgastados que el centro',
    'txt_lf_centreworn': 'El centro del neumático delantero izquierdo está más desgastado que ambos lados',
    'txt_lb_outworn': 'El exterior del neumático trasero izquierdo está más desgastado que el interior',
    'txt_lb_innerworn': 'El interior del neumático trasero izquierdo está  más desgastado que el exterior.',
    'txt_lb_bothsideworn': 'Ambos lados del neumático trasero izquierdo están más desgastados que el centro',
    'txt_lb_centreworn': 'El centro del neumático trasero izquierdo está más desgastado en ambos lados',
    'txt_rb_outworn': 'El exterior de la parte trasera derecha está más desgastado que el interior.',
    'txt_rb_innerworn': 'El interior de la parte trasera derecha está más desgastado que el exterior.',
    'txt_rb_bothsideworn': 'Ambos lados de la parte trasera derecha están más desgastados que el centro.',
    'txt_rb_centreworn': 'El centro de la parte trasera derecha está más  desgastado en ambos lados.',
    'txt_rf_outworn': 'El exterior del neumático delantero derecho está más  desgastado que el interior',
    'txt_rf_innerworn': 'El interior del neumático delantero derecho está más desgastado que el exterior.',
    'txt_rf_bothsideworn': 'Ambos lados del neumático delantero derecho están  más desgastados que el centro.',
    'txt_rf_centreworn': 'El centro del neumático delantero derecho está más desgastado en ambos lados',

    'txt_tire_need_replace': 'Estado de desgaste de los neumáticos: reemplazarlo de inmediato',
    'txt_tire_boundary': 'Estado de desgaste de los neumáticos: límite',
    'txt_alignment_chassis': 'Se recomienda la alineación y la verificación del chasis',
    'txt_wear_uniform': 'El desgaste de los neumáticos es uniforme.',
    'txt_do_further_check': 'Por favor, verifique nuevamente',
    'txt_uneven_check': 'El neumático tiene un desgaste desigual,se sugiere que verifique nuevamente',
    'txt_tire_rotation': 'Sugerir para rotación de neumáticos',
    'txt_wear_normal': 'Desgaste es normal ',
    'txt_tire': 'Neumático',

    'time': 'Selección de tiempo',
    'today': 'Hoy',
    'yesterday': 'Ayer',
    'week': 'Esta semana',
    'month': 'Este mes',
    'last_month': 'El mes pasado',
    'year': 'Este año.',
    'language': 'Seleccione el idioma',
    'zh': 'chino',
    'en': 'inglés',
    'ma': 'malayo',
    'ge': 'alemán',
    'fr': 'francés',
    'lt': 'italiano',
    'sp': 'español',
    'tw':'Chino tradicional',
    'jp':'Japonés',
    'po':'Polonia',
    'download': 'Descargar',
    'por':'Portugués',
    'advise': 'proponer：El neumático está desgarrado, por lo que se recomienda una nueva inspección',


    'txt_tire_age_5': 'Los neumáticos están disponibles desde hace más de 5 años y se recomienda una revisión completa de los neumáticos.',
    'txt_tire_age_3': 'Los neumáticos están disponibles desde hace más de 3 años y se recomienda una revisión completa de los neumáticos;',
    'txt_tire_age_ok': 'La edad de los neumáticos es normal;',
    'txt_tire_trauma_check': 'La superficie del neumático tiene UN traumatismo y se recomienda UN examen adicional;',
    'txt_install_abnormal_check': 'Montaje anormal de los neumáticos, se recomienda una revisión adicional;',
    'txt_tire_appearance_ok': 'Buen aspecto de los neumáticos;',
    'txt_pinnate_check': 'El neumático está desgastado de plumas y se recomienda un nuevo examen del chasis.',
    'trauma_lacerate': 'Cortar',
    'trauma_dropblock': 'DiaoKuai',
    'trauma_puncture': 'Punción',
    'trauma_scratch': 'Magulladuras',
    'trauma_pinnate': 'Desgaste de pluma',
    'txt_tire_age': 'Edad del neumático',
    'txt_tire appearance': 'Aspecto de los neumáticos',
    'tire_installed': 'Montaje de los neumáticos',
    'abnormal': 'Interno y externo',
    'endurance': 'Estimación de la autonomía de los neumáticos',
    'endurance-warn': 'El kilometraje restante es de {number}km',
    'endurance-zero': 'Los neumáticos están al límite. Por favor, cambien inmediatamente.',
    'estimated_value': 'Evaluación previa',
    'abrasion': 'Desgaste',
    'remanent': 'Los restantes {days} días de vida del neumático.',
    'useful_life': 'Estimación de la edad del neumático.',
    'dot_explain': 'DOT：Tiempo de fabricación del neumático',
    'unit_km': 'Unidad：km',
    'unit_year': 'Unidad：año',
    'dot_no': 'Tiempo de producción no disponible',
    'made_time': 'Período de producción: {year} (semana {year} del año {week})',
    'exceed_five': 'Los neumáticos tienen más de cinco años de vida.Por favor,cambien inmediatamente.',
    'inspector': 'Técnico',
    'txt_install_check': 'Inspección de instalación',
    'txt_install_ok': 'Instalación normal',
    'txt_pinnate_wear': 'Desgaste de pluma',



  'LFtxt_tire_need_replace': '[L-F]Estado de desgaste de los neumáticos: reemplazarlo de inmediato',
  'RFtxt_tire_need_replace': '[R-F]Estado de desgaste de los neumáticos: reemplazarlo de inmediato',
  'LBtxt_tire_need_replace': '[L-R]Estado de desgaste de los neumáticos: reemplazarlo de inmediato',
  'RBtxt_tire_need_replace': '[R-R]Estado de desgaste de los neumáticos: reemplazarlo de inmediato',
  'FFtxt_tire_need_replace': '[F-F]Estado de desgaste de los neumáticos: reemplazarlo de inmediato',
  'BBtxt_tire_need_replace': '[B-B]Estado de desgaste de los neumáticos: reemplazarlo de inmediato',


  'txt_pattern_wear': 'Usura irregolare',
  'txt_uneven_center': 'Desgaste anormal en el medio del neumático.',
  'LFtxt_uneven_center': '[L-F]Desgaste anormal en el medio del neumático.',
  'RFtxt_uneven_center': '[R-F]Desgaste anormal en el medio del neumático.',
  'LBtxt_uneven_center': '[L-R]Desgaste anormal en el medio del neumático.',
  'RBtxt_uneven_center': '[R-R]Desgaste anormal en el medio del neumático.',
  'FFtxt_uneven_center': '[F-F]Desgaste anormal en el medio del neumático.',
  'BBtxt_uneven_center': '[B-B]Desgaste anormal en el medio del neumático.',

  'txt_uneven_out': 'El desgaste de la parte exterior del neumático es anormal.',
  'LFtxt_uneven_out': '[L-F]El desgaste de la parte exterior del neumático es anormal.',
  'RFtxt_uneven_out': '[R-F]El desgaste de la parte exterior del neumático es anormal.',
  'LBtxt_uneven_out': '[L-R]El desgaste de la parte exterior del neumático es anormal.',
  'RBtxt_uneven_out': '[R-R]El desgaste de la parte exterior del neumático es anormal.',
  'FFtxt_uneven_out': '[F-F]El desgaste de la parte exterior del neumático es anormal.',
  'BBtxt_uneven_out': '[B-B]El desgaste de la parte exterior del neumático es anormal.',

  'LFtxt_pinnate_check':'[L-F]El neumático está desgastado de plumas y se recomienda un nuevo examen del chasis.',
  'RFtxt_pinnate_check':'[R-F]El neumático está desgastado de plumas y se recomienda un nuevo examen del chasis.',
  'LBtxt_pinnate_check':'[L-R]El neumático está desgastado de plumas y se recomienda un nuevo examen del chasis.',
  'RBtxt_pinnate_check':'[R-R]El neumático está desgastado de plumas y se recomienda un nuevo examen del chasis.',
  'FFtxt_pinnate_check':'[F-F]El neumático está desgastado de plumas y se recomienda un nuevo examen del chasis.',
  'BBtxt_pinnate_check':'[B-B]El neumático está desgastado de plumas y se recomienda un nuevo examen del chasis.',

  'txt_uneven_inside': 'Marcas de desgaste en el interior del neumático. Verifique la posición y presión del neumático.',
  'LFtxt_uneven_inside': '[L-F]Marcas de desgaste en el interior del neumático. Verifique la posición y presión del neumático.',
  'RFtxt_uneven_inside': '[R-F]Marcas de desgaste en el interior del neumático. Verifique la posición y presión del neumático.',
  'LBtxt_uneven_inside': '[L-R]Marcas de desgaste en el interior del neumático. Verifique la posición y presión del neumático.',
  'RBtxt_uneven_inside': '[R-R]Marcas de desgaste en el interior del neumático. Verifique la posición y presión del neumático.',
  'FFtxt_uneven_inside': '[F-F]Marcas de desgaste en el interior del neumático. Verifique la posición y presión del neumático.',
  'BBtxt_uneven_inside': '[B-B]Marcas de desgaste en el interior del neumático. Verifique la posición y presión del neumático.',

  'FFtxt_uneven_check': '[F-F]El neumático tiene un desgaste desigual,se sugiere que verifique nuevamente',
  'BBtxt_uneven_check': '[B-B]El neumático tiene un desgaste desigual,se sugiere que verifique nuevamente',


  'LFtxt_tire_age_5': '[L-F]Los neumáticos están disponibles desde hace más de 5 años y se recomienda una revisión completa de los neumáticos.',
  'RFtxt_tire_age_5': '[R-F]Los neumáticos están disponibles desde hace más de 5 años y se recomienda una revisión completa de los neumáticos.',
  'LBtxt_tire_age_5': '[L-R]Los neumáticos están disponibles desde hace más de 5 años y se recomienda una revisión completa de los neumáticos.',
  'RBtxt_tire_age_5': '[R-R]Los neumáticos están disponibles desde hace más de 5 años y se recomienda una revisión completa de los neumáticos.',
  'FFtxt_tire_age_5': '[F-F]Los neumáticos están disponibles desde hace más de 5 años y se recomienda una revisión completa de los neumáticos.',
  'BBtxt_tire_age_5':  '[B-B]Los neumáticos están disponibles desde hace más de 5 años y se recomienda una revisión completa de los neumáticos.',

  'txt_appearance_check':'El neumático tiene un aspecto anormal.',
  'LFtxt_appearance_check':'[L-F]El neumático tiene un aspecto anormal.',
  'RFtxt_appearance_check':'[R-F]El neumático tiene un aspecto anormal.',
  'LBtxt_appearance_check':'[L-R]El neumático tiene un aspecto anormal.',
  'RBtxt_appearance_check':'[R-R]El neumático tiene un aspecto anormal.',
  'FFtxt_appearance_check':'[F-F]El neumático tiene un aspecto anormal.',
  'BBtxt_appearance_check':'[B-B]El neumático tiene un aspecto anormal.',


  'LFtxt_install_abnormal_check':'[L-F]Montaje anormal de los neumáticos, se recomienda una revisión adicional.',
  'RFtxt_install_abnormal_check':'[R-F]Montaje anormal de los neumáticos, se recomienda una revisión adicional.',
  'LBtxt_install_abnormal_check':'[L-R]Montaje anormal de los neumáticos, se recomienda una revisión adicional.',
  'RBtxt_install_abnormal_check':'[R-R]Montaje anormal de los neumáticos, se recomienda una revisión adicional.',
  'FFtxt_install_abnormal_check':'[F-F]Montaje anormal de los neumáticos, se recomienda una revisión adicional.',
  'BBtxt_install_abnormal_check':'[B-B]Montaje anormal de los neumáticos, se recomienda una revisión adicional.',

  'txt_uneven_bothside': 'El desgaste de ambos lados del neumático es anormal.\'  txt_pinnate_check:\'Los neumáticos tienen un desgaste de plumas, por favor comprueben más.',
  'LFtxt_uneven_bothside': '[L-F]El desgaste de ambos lados del neumático es anormal.\'  txt_pinnate_check:\'Los neumáticos tienen un desgaste de plumas, por favor comprueben más.',
  'RFtxt_uneven_bothside': '[R-F]El desgaste de ambos lados del neumático es anormal.\'  txt_pinnate_check:\'Los neumáticos tienen un desgaste de plumas, por favor comprueben más.',
  'LBtxt_uneven_bothside': '[L-R]El desgaste de ambos lados del neumático es anormal.\'  txt_pinnate_check:\'Los neumáticos tienen un desgaste de plumas, por favor comprueben más.',
  'RBtxt_uneven_bothside': '[R-R]El desgaste de ambos lados del neumático es anormal.\'  txt_pinnate_check:\'Los neumáticos tienen un desgaste de plumas, por favor comprueben más.',
  'FFtxt_uneven_bothside': '[F-F]El desgaste de ambos lados del neumático es anormal.\'  txt_pinnate_check:\'Los neumáticos tienen un desgaste de plumas, por favor comprueben más.',
  'BBtxt_uneven_bothside': '[B-B]El desgaste de ambos lados del neumático es anormal.\'  txt_pinnate_check:\'Los neumáticos tienen un desgaste de plumas, por favor comprueben más.',


  'txt_wear_lookup': 'Compruebe el desgaste del neumático.',
  'LFtxt_wear_lookup': '[L-F]Compruebe el desgaste del neumático.',
  'RFtxt_wear_lookup': '[R-F]Compruebe el desgaste del neumático.',
  'LBtxt_wear_lookup': '[L-B]Compruebe el desgaste del neumático.',
  'RBtxt_wear_lookup': '[R-B]Compruebe el desgaste del neumático.',
  'FFtxt_wear_lookup': '[F-F]Compruebe el desgaste del neumático.',
  'BBtxt_wear_lookup': '[B-B]Compruebe el desgaste del neumático.',

  'txt_appearance_lookup': 'Compruebe la apariencia de los neumáticos.',
  'LFtxt_appearance_lookup': '[L-F]Compruebe la apariencia de los neumáticos.',
  'RFtxt_appearance_lookup': '[R-F]Compruebe la apariencia de los neumáticos.',
  'LBtxt_appearance_lookup': '[F-B]Compruebe la apariencia de los neumáticos.',
  'RBtxt_appearance_lookup': '[R-B]Compruebe la apariencia de los neumáticos.',
  'FFtxt_appearance_lookup': '[F-F]Compruebe la apariencia de los neumáticos.',
  'BBtxt_appearance_lookup': '[B-B]Compruebe la apariencia de los neumáticos.',

  'txt_serious_trauma_check': 'Hay una herida grave en el neumático.',
  'LFtxt_serious_trauma_check': '[L-F]Hay una herida grave en el neumático.',
  'RFtxt_serious_trauma_check': '[R-F]Hay una herida grave en el neumático.',
  'LBtxt_serious_trauma_check': '[L-B]Hay una herida grave en el neumático.',
  'RBtxt_serious_trauma_check': '[R-B]Hay una herida grave en el neumático.',
  'FFtxt_serious_trauma_check': '[F-F]Hay una herida grave en el neumático.',
  'BBtxt_serious_trauma_check': '[B-B]Hay una herida grave en el neumático.',

   'purp_tireservice':'Servicios de neumáticos',
   'purp_vehicle_inspection':'Inspección de vehículos',

   'merchant': 'Comerciante',
   'manager': 'Tendero',
   'assistant': 'Vendedora',

  'txt_chassis_check': 'Verificación del chasis',
  'txt_chassis_ok': 'Revisión del chasis.',
  'LFtxt_chassis_ok': '[L-F]Revisión del chasis.',
  'RFtxt_chassis_ok': '[R-F]Revisión del chasis.',
  'LBtxt_chassis_ok': '[L-B]Revisión del chasis.',
  'RBtxt_chassis_ok': '[R-R]Revisión del chasis.',
  'FFtxt_chassis_ok': '[F-F]Revisión del chasis.',
  'BBtxt_chassis_ok': '[B-B]Revisión del chasis.',
  'txt_chassis_oil': 'El amortiguador gotea aceite, se recomienda una nueva confirmación y, de ser necesario, cambia el tratamiento.',
   'LFtxt_chassis_oil': '[L-F]El amortiguador gotea aceite, se recomienda una nueva confirmación y, de ser necesario, cambia el tratamiento.',
  'RFtxt_chassis_oil': '[R-F]El amortiguador gotea aceite, se recomienda una nueva confirmación y, de ser necesario, cambia el tratamiento.',
  'LBtxt_chassis_oil': '[L-B]El amortiguador gotea aceite, se recomienda una nueva confirmación y, de ser necesario, cambia el tratamiento.',
  'RBtxt_chassis_oil': '[R-R]El amortiguador gotea aceite, se recomienda una nueva confirmación y, de ser necesario, cambia el tratamiento.',
  'FFtxt_chassis_oil': '[F-F]El amortiguador gotea aceite, se recomienda una nueva confirmación y, de ser necesario, cambia el tratamiento.',
  'BBtxt_chassis_oil': '[B-B]El amortiguador gotea aceite, se recomienda una nueva confirmación y, de ser necesario, cambia el tratamiento.',
  'txt_chassis_aging': 'El caucho del eje está envejeciendo y se recomienda su sustitución.',
  'LFtxt_chassis_aging': '[L-F]El caucho del eje está envejeciendo y se recomienda su sustitución.',
  'RFtxt_chassis_aging': '[R-F]El caucho del eje está envejeciendo y se recomienda su sustitución.',
  'LBtxt_chassis_aging': '[L-B]El caucho del eje está envejeciendo y se recomienda su sustitución.',
  'RBtxt_chassis_aging': '[R-R]El caucho del eje está envejeciendo y se recomienda su sustitución.',
  'FFtxt_chassis_aging': '[F-F]El caucho del eje está envejeciendo y se recomienda su sustitución.',
  'BBtxt_chassis_aging': '[B-B]El caucho del eje está envejeciendo y se recomienda su sustitución.',
  'awd': 'App Download',

  'depth': 'Profundidad de la trinchera',
  'usura': 'Desgaste parcial',
  'usura-l': 'Patrón uniforme de desgaste',
  'usura-m': 'Desgaste parcial leve',
  'usura-h': 'Desgaste parcial grave',

  'engine-hood': 'CAPÓ DEL MOTOR',
  'change-control': 'OTROS CONTROLES',
  'subtotal': 'BAJO CARROCERIA',

  'replace': 'REEMPLAZAR',
  'vedi': 'LEER NOTAS',
  'ok': 'OK',

  'liquid': 'LIMPIADOR DE VENTANAS',
  'level-control': 'CONTROL DE NIVEL',
  'air': 'AIRE ACONDICIONADO',
  'last-reload': 'ÚLTIMA RECARGA',
  'absorber': 'AMORTIGUADORES',
  'check-loss': 'CONTROL FUGAS',
  'fluid': 'LÍQUIDO DEL RADIADOR',
  'lighting': 'FAROS',
  'verification': 'CONTROL FUNCIONAMIENTO',
  'disc': 'DISCOS DE FRENOS',
  'condition': 'ESTADO DE DESGASTE',
  'oil': 'ACEITE DEL MOTOR',
  'wiper': 'LIMPIAPARABRISAS',
  'pads': 'PASTILLAS DE FRENOS',
  'brake-fluid': 'LÍQUIDO DE LOS FRENOS',
  'quality-control': 'CONTROL DE CALIDAD',
  'battery': 'BATERÍA',
  'multimetro': 'TEST MULTÍMETRO',
   'control-quality': 'CONTROL DE CALIDAD',
   'tightening': 'APRIETE CON DINAMOMÉTRO HECHA POR',
  'buone': 'Ok',
  'lavoro': 'Programación del trabajo',
  'concordare': 'Permitir la sustitución',
  'season_summer': 'Neumáticos de verano',
  'season_winter': 'Neumático de invierno',

  'com':'Commercial',
  'pass':'Passenger',
}
