import React, {Component} from "react";
import "./LorDetail.scss"
import Header from "../../../components/Lorry/Header";
import Suggestion from "../../../components/Lorry/Suggestion";
import Content from "../../../components/Lorry/Content";
import returnTireData from "../../../lordata";

class LorDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            report: null
        }
    };

    componentDidMount() {
        this.getRecordDetail();
    }

    // 获取检测报告详情
    getRecordDetail() {
        global.Api.getLorDetail(this.props.match.params.id).then(res => {
            if (res.data.code === 0) {
                this.setState({
                    report: res.data.data.report
                });
            } else {
                console.log(res.data.message);
            }
        });
    }

    render() {
        if (this.state.report) {
            let {logo, car, device_time, tires, inspector, plate_no, uuid, language, tyreunit} = this.state.report
            let car_pos  = car.positions;
            let tireList = [];
            let flag = 0
            car_pos.forEach((item)=>{
                // console.log(item.position.slice(0,2));
                flag = flag === 4 ? 0 : flag += 1
            });
            // console.log(flag);

            let headerData = {
                plate_no: plate_no,
                logo: logo,
                device_time: device_time.replace("T", " "),
                inspector: inspector,
                en_name: car.en_name,
                genre: car.genre,
                uuid: uuid,
                language: language
            };



            return (
                     <div className='lor-report'>
                <div className="lor-header">
                    <Header data={headerData}/>
                </div>
                <div>
                    <Suggestion data ={{tires: tires}} />
                </div>
                <div className="lor-content">
                    <Content data ={{tireData: returnTireData(car.en_name, tires), genre: car.genre, unit: tyreunit}}/>
                </div>
            </div>
            )

        } else {
            return <div></div>
        }
    }

}

export default LorDetail