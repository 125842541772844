export default {
    'login': 'Deconnexion',
    'input_name': 'Veuillez indiquer votre nom d’utilisateur',
    'input_password': 'Veuillez introduire votre mot de passe',
    'login_su': 'Accès réussi',
    'role': 'Rôle',
    'username': 'Nom d \'utilisateur',
    'password': 'Mot de passe',

    'store': 'Nom du magasin',
    'number': 'numéro',
    'device_time': 'Heure de détection',
    'wear_co': 'État d’usure',
    'ecc_co': 'Cas de déviation',
    'plate_no': 'plaque d\'immatriculation',
    'cus_ph': 'Portable',
    'car_type': 'Type de véhicule',
    'operation': 'opération',
    'view': 'détails',
    'cus_email': 'Courriel du client',
    'cus_name': 'Nom du client',


    'print': ' Imprimer',
    'store_name': 'Boutique',
    'address': 'Adresse de boutique',
    'model': 'Type de véhicule',
    'mileage': 'Kilométrage',
    'device': 'Equipement de détection',
    'remark': 'note',
    'purpose': 'But',
    're': 'Recommandations de détection',
    'color_flag': 'Identification en couleur',
    'wear': 'Usure globale',
    'brake': 'Rappel de la distance de freinage actuelle',
    'ecc': 'Usure inégale',
    'lf': 'L-F',
    'lr': 'L-R',
    'rf': 'R-F',
    'rr': 'R-R',
    'untest': 'Pneumatiques non détectés',
    'low': 'Normal',
    'middle': 'Limite',
    'high': 'Remplacer',
    'avg': 'Moyenne',
    'spec': "Taille",
    'brand': 'Marque',
    'ecc_avg': 'Usure uniforme de sculpture',
    'trauma': 'Dommage de pneu',
    'zc': 'normal',
    'image': 'zh',
    'qr': 'Code scanner pour la lecture du journal',
    'model_number': 'HH Tire Tread Depth Detector',
    'purp_wash': 'Lavage&Beauté automobile',
    'purp_alignment': 'Alignement de 4-roues',
    'purp_replace': 'Remplacement de pneu',
    'purp_maintain': 'Maintenance',
    'warn': 'rappeler：Selon l\'usure des pneus, la distance de freinage est augmentée',
    'trauma_normal': 'normal',
    'trauma_bulge': 'Renflement',
    'trauma_aging': 'Vieillissement',

    'txt_lf_outworn': 'Le côté extérieur du pneu AVG est plus usé que le côté intérieur.',
    'txt_lf_innerworn': 'Le côté intérieur du pneu AVG est plus usé que le côté extérieur.',
    'txt_lf_bothsideworn': 'Les deux côtés du pneu AVG sont plus usés que le centre',
    'txt_lf_centreworn': 'Le centre du pneu AVG est plus usé que les deux côtés',
    'txt_lb_outworn': 'Le côté extérieur du pneu ARG est plus usé que le côté intérieur.',
    'txt_lb_innerworn': 'Le côté intérieur du pneu ARG est plus usé que le côté extérieur.',
    'txt_lb_bothsideworn': 'Les deux côtés du pneu ARG sont plus usés que le centre',
    'txt_lb_centreworn': 'Le centre du pneu ARG est plus usé que les deux côtés',
    'txt_rb_outworn': 'Le côté extérieur du pneu ARD est plus usé que le côté intérieur.',
    'txt_rb_innerworn': 'Le côté intérieur du pneu ARD est plus usé que le côté extérieur.',
    'txt_rb_bothsideworn': 'Les deux côtés du pneu ARD sont plus usés que le centre',
    'txt_rb_centreworn': 'Le centre du pneu ARD est plus usé que les deux côtés',
    'txt_rf_outworn': 'Le côté extérieur du pneu AVD est plus usé que le côté intérieur.',
    'txt_rf_innerworn': 'Le côté intérieur du pneu AVD est plus usé que le côté extérieur.',
    'txt_rf_bothsideworn': 'Les deux côtés du pneu AVD sont plus usés que le centre',
    'txt_rf_centreworn': 'Le centre du pneu AVD est plus usé que les deux côtés',

    'txt_tire_need_replace': 'Usure du pneu, remplacez immédiatement',
    'txt_tire_boundary': 'A la limite par usure du pneu',
    'txt_alignment_chassis': 'Il est recommandé de vérifier l\'alignement de 4-roues ou le châssis',
    'txt_wear_uniform': 'Usure uniforme d\'un pneu',
    'txt_do_further_check': 'Veuillez vérifier davantage',
    'txt_uneven_check': 'Usure inégale, veuillez vérifier davantage',
    'txt_tire_rotation': 'Il est recommandé d\'interchanger les pneus',
    'txt_wear_normal': 'Usure normale de pneu',
    'txt_tire': 'Pneu',

    'time': 'Choix du moment',
    'today': 'Aujourd\'hui',
    'yesterday': 'Hier',
    'week': 'Cette semaine',
    'month': 'Ce mois',
    'last_month': 'Dernier mois',
    'year': 'Cette année',
    'language': 'Choix de la langue',
    'zh': 'Chinois',
    'en': 'Anglais',
    'ma': 'malais',
    'ge': 'allemand',
    'fr': 'français',
    'lt': 'italien',
    'sp': 'espagnol',
    'tw': 'Corps',
    'jp': 'Japonais',
    'po': 'Pologne',
    'por': 'Portugais',
    'download': 'scaricare',
    'advise': 'proposer：Les pneumatiques sont déviés et un contrôle supplémentaire est recommandé',

    'txt_tire_age_5': 'Il est recommandé de procéder à un contrôle complet des pneumatiques qui sont en service depuis plus de cinq ans;',
    'txt_tire_age_3': 'Il est recommandé de procéder à un contrôle complet des pneumatiques qui sont en service depuis plus de trois ans;',
    'txt_tire_age_ok': 'L’âge des pneumatiques est normal;',
    'txt_tire_trauma_check': 'La surface du pneumatique présente un traumatisme et un examen supplémentaire est recommandé;',
    'txt_install_abnormal_check': 'Montage anormal du pneumatique, un contrôle supplémentaire est recommandé;',
    'txt_tire_appearance_ok': 'Les pneus ont une bonne apparence;',
    'txt_pinnate_check': 'L \'usure des pneus en plume suggère un examen plus poussé de la plaque de châssis',
    'trauma_lacerate': 'Couper',
    'trauma_dropblock': 'DiaoKuai',
    'trauma_puncture': 'Ponction',
    'trauma_scratch': 'Contusions',
    'trauma_pinnate': 'Plume Wear',
    'txt_tire_age': 'Âge du pneumatique',
    'txt_tire appearance': 'Aspect du pneumatique',
    'tire_installed': 'Montage des pneumatiques',
    'abnormal': 'Intérieur et extérieur',
    'endurance': 'Estimation de la distance parcourue par le pneumatique',
    'endurance-warn': 'La distance restante est de {number}km',
    'endurance-zero': ' Les pneus ont atteint leur limite. Veuillez les remplacer immédiatement.',
    'estimated_value': 'Valeur estimée',
    'abrasion': 'Usure',
    'remanent': 'Il reste {days} jours pour les pneus.',
    'useful_life': 'Estimation de l’âge du pneumatique',
    'dot_explain': 'DOT：Temps de fabrication du pneumatique',
    'unit_km': 'Les unités：km',
    'unit_year': 'Les unités：année',
    'dot_no': 'Pas de temps de production',
    'made_time': 'Production time: {dot} ({year} year {week} week)',
    'exceed_five': 'Temps de production: {year} ({year} semaine {week} de l \'année).',
    'inspector': 'Technicien',
    'txt_install_check': 'Vérification d \'installation',
    'txt_install_ok': 'Installation normale',
    'txt_pinnate_wear': 'Plume Wear',


    'LFtxt_tire_need_replace': '[L-F]Usure du pneu, remplacez immédiatement',
    'RFtxt_tire_need_replace': '[R-F]Usure du pneu, remplacez immédiatement',
    'LBtxt_tire_need_replace': '[L-R]Usure du pneu, remplacez immédiatement',
    'RBtxt_tire_need_replace': '[R-R]Usure du pneu, remplacez immédiatement',
    'FFtxt_tire_need_replace': '[F-F]Usure du pneu, remplacez immédiatement',
    'BBtxt_tire_need_replace': '[B-B]Usure du pneu, remplacez immédiatement',


    'txt_pattern_wear': 'Usure inégale',
    'txt_uneven_center': 'Anomalie d \'usure au milieu du pneu',
    'LFtxt_uneven_center': '[L-F]Anomalie d \'usure au milieu du pneu',
    'RFtxt_uneven_center': '[R-F]Anomalie d \'usure au milieu du pneu',
    'LBtxt_uneven_center': '[L-R]Anomalie d \'usure au milieu du pneu',
    'RBtxt_uneven_center': '[R-R]Anomalie d \'usure au milieu du pneu',
    'FFtxt_uneven_center': '[F-F]Anomalie d \'usure au milieu du pneu',
    'BBtxt_uneven_center': '[B-B]Anomalie d \'usure au milieu du pneu',

    'txt_uneven_out': 'Anomalie d \'usure du flanc extérieur du pneu',
    'LFtxt_uneven_out': '[L-F]Anomalie d \'usure du flanc extérieur du pneu',
    'RFtxt_uneven_out': '[R-F]Anomalie d \'usure du flanc extérieur du pneu',
    'LBtxt_uneven_out': '[L-R]Anomalie d \'usure du flanc extérieur du pneu',
    'RBtxt_uneven_out': '[R-R]Anomalie d \'usure du flanc extérieur du pneu',
    'FFtxt_uneven_out': '[F-F]Anomalie d \'usure du flanc extérieur du pneu',
    'BBtxt_uneven_out': '[B-B]Anomalie d \'usure du flanc extérieur du pneu',

    'LFtxt_pinnate_check ': '[L-F]L \'usure des pneus en plume suggère un examen plus poussé de la plaque de châssis',
    'RFtxt_pinnate_check ': '[R-F]L \'usure des pneus en plume suggère un examen plus poussé de la plaque de châssis',
    'LBtxt_pinnate_check ': '[L-R]L \'usure des pneus en plume suggère un examen plus poussé de la plaque de châssis',
    'RBtxt_pinnate_check ': '[R-R]L \'usure des pneus en plume suggère un examen plus poussé de la plaque de châssis',
    'FFtxt_pinnate_check ': '[F-F]L \'usure des pneus en plume suggère un examen plus poussé de la plaque de châssis',
    'BBtxt_pinnate_check ': '[B-B]L \'usure des pneus en plume suggère un examen plus poussé de la plaque de châssis',

    'txt_uneven_inside': 'Anomalie d \'usure latérale interne du pneu',
    'LFtxt_uneven_inside': '[L-F]Anomalie d \'usure latérale interne du pneu',
    'RFtxt_uneven_inside': '[R-F]Anomalie d \'usure latérale interne du pneu',
    'LBtxt_uneven_inside': '[L-R]Anomalie d \'usure latérale interne du pneu',
    'RBtxt_uneven_inside': '[R-R]Anomalie d \'usure latérale interne du pneu',
    'FFtxt_uneven_inside': '[F-F]Anomalie d \'usure latérale interne du pneu',
    'BBtxt_uneven_inside': '[B-B]Anomalie d \'usure latérale interne du pneu',

    'FFtxt_uneven_check': '[F-F]Usure inégale, veuillez vérifier davantage',
    'BBtxt_uneven_check': '[B-B]Usure inégale, veuillez vérifier davantage',


    'LFtxt_tire_age_5': '[L-F]Il est recommandé de procéder à un contrôle complet des pneumatiques qui sont en service depuis plus de cinq ans;',
    'RFtxt_tire_age_5': '[R-F]Il est recommandé de procéder à un contrôle complet des pneumatiques qui sont en service depuis plus de cinq ans;',
    'LBtxt_tire_age_5': '[L-R]Il est recommandé de procéder à un contrôle complet des pneumatiques qui sont en service depuis plus de cinq ans;',
    'RBtxt_tire_age_5': '[R-R]Il est recommandé de procéder à un contrôle complet des pneumatiques qui sont en service depuis plus de cinq ans;',
    'FFtxt_tire_age_5': '[F-F]Il est recommandé de procéder à un contrôle complet des pneumatiques qui sont en service depuis plus de cinq ans;',
    'BBtxt_tire_age_5': '[B-B]Il est recommandé de procéder à un contrôle complet des pneumatiques qui sont en service depuis plus de cinq ans;',

    'txt_appearance_check': 'Anomalie du pneu',
    'LFtxt_appearance_check': '[L-F]Anomalie du pneu',
    'RFtxt_appearance_check': '[R-F]Anomalie du pneu',
    'LBtxt_appearance_check': '[L-R]Anomalie du pneu',
    'RBtxt_appearance_check': '[R-R]Anomalie du pneu',
    'FFtxt_appearance_check': '[F-F]Anomalie du pneu',
    'BBtxt_appearance_check': '[B-B]Anomalie du pneu',


    'LFtxt_install_abnormal_check': '[L-F]Montage anormal du pneumatique, un contrôle supplémentaire est recommandé;',
    'RFtxt_install_abnormal_check': '[R-F]Montage anormal du pneumatique, un contrôle supplémentaire est recommandé;',
    'LBtxt_install_abnormal_check': '[L-R]Montage anormal du pneumatique, un contrôle supplémentaire est recommandé;',
    'RBtxt_install_abnormal_check': '[R-R]Montage anormal du pneumatique, un contrôle supplémentaire est recommandé;',
    'FFtxt_install_abnormal_check': '[F-F]Montage anormal du pneumatique, un contrôle supplémentaire est recommandé;',
    'BBtxt_install_abnormal_check': '[B-B]Montage anormal du pneumatique, un contrôle supplémentaire est recommandé;',

    'txt_uneven_bothside': 'Anomalie d \'usure des deux côtés du pneu, il est recommandé de vérifier le positionnement des quatre roues et la pression du pneu.',
    'LFtxt_uneven_bothside': '[L-F]Anomalie d \'usure des deux côtés du pneu, il est recommandé de vérifier le positionnement des quatre roues et la pression du pneu.',
    'RFtxt_uneven_bothside': '[R-F]Anomalie d \'usure des deux côtés du pneu, il est recommandé de vérifier le positionnement des quatre roues et la pression du pneu.',
    'LBtxt_uneven_bothside': '[L-R]Anomalie d \'usure des deux côtés du pneu, il est recommandé de vérifier le positionnement des quatre roues et la pression du pneu.',
    'RBtxt_uneven_bothside': '[R-R]Anomalie d \'usure des deux côtés du pneu, il est recommandé de vérifier le positionnement des quatre roues et la pression du pneu.',
    'FFtxt_uneven_bothside': '[F-F]Anomalie d \'usure des deux côtés du pneu, il est recommandé de vérifier le positionnement des quatre roues et la pression du pneu.',
    'BBtxt_uneven_bothside': '[B-B]Anomalie d \'usure des deux côtés du pneu, il est recommandé de vérifier le positionnement des quatre roues et la pression du pneu.',

    'txt_wear_lookup': "Vérifiez l'usure des pneus.",
    'LFtxt_wear_lookup': '[L-F]Vérifiez l\'usure des pneus.',
    'RFtxt_wear_lookup': '[R-F]Vérifiez l\'usure des pneus.',
    'LBtxt_wear_lookup': '[L-R]Vérifiez l\'usure des pneus.',
    'RBtxt_wear_lookup': '[R-R]Vérifiez l\'usure des pneus.',
    'FFtxt_wear_lookup': '[F-F]Vérifiez l\'usure des pneus.',
    'BBtxt_wear_lookup': '[B-B]Vérifiez l\'usure des pneus.',

    'txt_appearance_lookup': 'Vérifiez l\'apparence des pneus.',
    'LFtxt_appearance_lookup': '[L-F]Vérifiez l\'apparence des pneus.',
    'RFtxt_appearance_lookup': '[R-F]Vérifiez l\'apparence des pneus.',
    'LBtxt_appearance_lookup': '[L-B]Vérifiez l\'apparence des pneus.',
    'RBtxt_appearance_lookup': '[R-B]Vérifiez l\'apparence des pneus.',
    'FFtxt_appearance_lookup': '[F-F]Vérifiez l\'apparence des pneus.',
    'BBtxt_appearance_lookup': '[B-B]Vérifiez l\'apparence des pneus.',

    'txt_serious_trauma_check': 'Les pneus sont gravement traumatisés.',
    'LFtxt_serious_trauma_check': '[L-F]Les pneus sont gravement traumatisés.',
    'RFtxt_serious_trauma_check': '[R-F]Les pneus sont gravement traumatisés.',
    'LBtxt_serious_trauma_check': '[L-B]Les pneus sont gravement traumatisés.',
    'RBtxt_serious_trauma_check': '[R-B]Les pneus sont gravement traumatisés.',
    'FFtxt_serious_trauma_check': '[F-F]Les pneus sont gravement traumatisés.',
    'BBtxt_serious_trauma_check': '[B-B]Les pneus sont gravement traumatisés.',

    'purp_tireservice': 'Service de pneus',
    'purp_vehicle_inspection': 'Inspection des véhicules',
    'merchant': 'Le commerçant',
    'manager': 'Délégués syndicaux',
    'assistant': 'Le clerc',

    'txt_chassis_check': 'Contrôle de châssis',
    'txt_chassis_ok': 'Le châssis est bien vérifié.',
    'LFtxt_chassis_ok': '[L-F]Le châssis est bien vérifié.',
    'RFtxt_chassis_ok': '[R-F]Le châssis est bien vérifié.',
    'LBtxt_chassis_ok': '[L-B]Le châssis est bien vérifié.',
    'RBtxt_chassis_ok': '[R-R]Le châssis est bien vérifié.',
    'FFtxt_chassis_ok': '[F-F]Le châssis est bien vérifié.',
    'BBtxt_chassis_ok': '[B-B]Le châssis est bien vérifié.',
    'txt_chassis_oil': 'Fuites d\'huile dans l\'amortisseur, il est recommandé de confirmer ces fuites et de les remplacer si nécessaire.',
    'LFtxt_chassis_oil': '[L-F]Fuites d\'huile dans l\'amortisseur, il est recommandé de confirmer ces fuites et de les remplacer si nécessaire.',
    'RFtxt_chassis_oil': '[R-F]Fuites d\'huile dans l\'amortisseur, il est recommandé de confirmer ces fuites et de les remplacer si nécessaire.',
    'LBtxt_chassis_oil': '[L-B]Fuites d\'huile dans l\'amortisseur, il est recommandé de confirmer ces fuites et de les remplacer si nécessaire.',
    'RBtxt_chassis_oil': '[R-R]Fuites d\'huile dans l\'amortisseur, il est recommandé de confirmer ces fuites et de les remplacer si nécessaire.',
    'FFtxt_chassis_oil': '[F-F]Fuites d\'huile dans l\'amortisseur, il est recommandé de confirmer ces fuites et de les remplacer si nécessaire.',
    'BBtxt_chassis_oil': '[B-B]Fuites d\'huile dans l\'amortisseur, il est recommandé de confirmer ces fuites et de les remplacer si nécessaire.',
    'txt_chassis_aging': 'Le caoutchouc d \'arbre vieillit et il est recommandé de le remplacer.',
    'LFtxt_chassis_aging': '[L-F]Le caoutchouc d \'arbre vieillit et il est recommandé de le remplacer.',
    'RFtxt_chassis_aging': '[R-F]Le caoutchouc d \'arbre vieillit et il est recommandé de le remplacer.',
    'LBtxt_chassis_aging': '[L-B]Le caoutchouc d \'arbre vieillit et il est recommandé de le remplacer.',
    'RBtxt_chassis_aging': '[R-R]Le caoutchouc d \'arbre vieillit et il est recommandé de le remplacer.',
    'FFtxt_chassis_aging': '[F-F]Le caoutchouc d \'arbre vieillit et il est recommandé de le remplacer.',
    'BBtxt_chassis_aging': '[B-B]Le caoutchouc d \'arbre vieillit et il est recommandé de le remplacer.',
    'awd': 'App Download',

    'depth': 'Profondeur de la tranchée',
    'usura': 'Usure excentrique',
    'usura-l': 'Usure uniforme du modèle',
    'usura-m': 'Légère usure excentrique',
    'usura-h': 'Usure excentrique sévère',

    'engine-hood': 'CAPOT MOTEUR',
    'change-control': 'AUTRES CONTRÔLES',
    'subtotal': 'SOUS CHASSIS',

    'replace': 'REMPLACER',
    'vedi': 'LIRE NOTE',
    'ok': 'OK',

    'liquid': 'LIQUIDE LAVE-VITRES',
    'level-control': 'CONTRÔLE DU NIVEAU',
    'air': 'CLIMATISATION',
    'last-reload': 'DERNIÈRE RECHARGE',
    'absorber': 'AMORTISSEURS',
    'check-loss': 'CONTRÔLE DES FUITES VISIBLES',
    'fluid': 'LIQUIDE DE RADIATEUR',
    'lighting': 'FEUX',
    'verification': 'CONTRÔLE DU FONCTIONNEMENT',
    'disc': 'DISQUES DES FREINS',
    'condition': 'ÉTAT D\'USURE',
    'oil': 'HUILE DU MOTEUR',
    'wiper': 'ESSUIE-GLACE',
    'pads': 'LES PLAQUETTES DE FREIN',
    'brake-fluid': 'LIQUIDE DES FREINS',
    'quality-control': 'CONTRÔLE QUALITÉ',
    'battery': 'BATTERIE',
    'multimetro': 'TEST MULTIMÈTRE',
    'control-quality': 'CONTRÔLE QUALITÉ',
    'tightening': 'SERRAGE AVEC DYNAMOMÈTRE FAIT PAR',
    'buone': 'OK',
    'lavoro': 'Calendrier des travaux',
    'concordare': 'Autoriser le remplacement',
    'season_summer': ' Pneu Été',
    'season_winter': 'Pneu Hiver',

    'com': 'Utilitaires',
    'pass': 'Particulières',

}
