export default {
  'login':'登录',
  'input_name':'请输入用户名',
  'input_password':'请输入密码',
  'login_su':'登录成功',
  'role':'角色',
  'username':'用户名',
  'password':'密码',


  'store':'门店名称',
  'number':'编号',
  'device_time':'检测时间',
  'wear_co':'磨损情况(左前-右前-左后-右后)',
  'ecc_co':'偏磨情况(左前-右前-左后-右后)',
  'plate_no':'车牌号码',
  'cus_ph':'客户手机',
  'car_type':'汽车车型',
  'operation':'操作',
  'view':'查看详情',
  'cus_email': '客户邮箱',
  'cus_name': '客户名称',


  'print':'打印(需勾选背景图形)',
  'store_name':'服务门店',
  'address':'门店地址',
  'model':'车型',
  'mileage':'里程',
  'device':'检测设备',
  'remark':'备注',
  'purpose':'到店目的',
  're':'检测建议',
  'color_flag':'颜色标识',
  'wear':'整体磨损',
  'brake':'当前刹车距离提醒',
  'ecc':'异常磨损',
  'lf':'左前轮',
  'lr':'左后轮',
  'rf':'右前轮',
  'rr':'右后轮',
  'untest':'轮胎未检测',
  'low':'良好状态',
  'middle':'边界状态',
  'high':'立即更换',
  'avg':'平均值',
  'spec':"规格",
  'brand':'品牌',
  'ecc_avg':'花纹磨损均匀',
  'trauma':'轮胎外观',
  'zc':'正常',
  'image':'zh',
  'qr':'扫描二维码查看报告',
  'model_number':'手持式轮胎检测仪',
  'purp_wash':'洗车美容',
  'purp_alignment':'四轮定位',
  'purp_replace':'更换轮胎',
  'purp_maintain':'维修保养',
  'warn': '提醒：根据当前轮胎磨损情况，可能导致刹车距离增加',
  'trauma_normal':'正常',
  'trauma_bulge':'鼓包',
  'trauma_aging':'老化',

  'txt_lf_outworn':'左前胎外侧比内侧磨损严重',
  'txt_lf_innerworn':'左前胎内侧比外侧磨损严重',
  'txt_lf_bothsideworn':'左前胎两侧比中间磨损严重',
  'txt_lf_centreworn':'左前胎中间比两侧磨损严重',
  'txt_lb_outworn':'左后胎外侧比内侧磨损严重',
  'txt_lb_innerworn':'左后胎内侧比外侧磨损严重',
  'txt_lb_bothsideworn':'左后胎两侧比中间磨损严重',
  'txt_lb_centreworn':'左后胎中间比两侧磨损严重',
  'txt_rb_outworn':'右后胎外侧比内侧磨损严重',
  'txt_rb_innerworn': '右后胎内侧比外侧磨损严重',
  'txt_rb_bothsideworn': '右后胎两侧比中间磨损严重',
  'txt_rb_centreworn': '右后胎中间比两侧磨损严重',
  'txt_rf_outworn': '右前胎外侧比内侧磨损严重',
  'txt_rf_innerworn':  '右前胎内侧比外侧磨损严重',
  'txt_rf_bothsideworn': '右前胎两侧比中间磨损严重',
  'txt_rf_centreworn': '右前胎中间比两侧磨损严重' ,
  'txt_ecc_abnormal': '轮胎异常偏磨',

  'txt_tire_need_replace':          '轮胎磨损严重，请立即更换',
  'txt_tire_boundary':              '轮胎磨损已处边界状态，请定期进行检查',
  'txt_alignment_chassis':          '建议进一步进行四轮定位或底盘检测',
  'txt_wear_uniform':               '轮胎磨损较均匀',
  'txt_do_further_check':           '请进一步检查',
  'txt_uneven_check':                '轮胎磨损异常，建议动平衡及对四轮定位,悬架系统进行检查',
  'txt_tire_rotation':              '建议轮胎换位',
  'txt_wear_normal':                '轮胎磨损正常',
  'txt_tire':                       '轮胎',

  'time':'请选择时间',
  'today':'今天',
  'yesterday':'昨天',
  'week':'本周',
  'month':'本月',
  'last_month':'上月',
  'year':'今年',
  'language':'请选择语言',
  'zh':'中文',
  'en':'英语',
  'ma':'马来语',
  'ge':'德语',
  'fr':'法语',
  'lt':'意大利语',
  'sp':'西班牙语',
  'tw':'繁体',
  'jp':'日语',
  'po':'波兰语',
  'por':'葡萄牙语',
  'download':'下载报告',
  'advise': '建议：轮胎存在偏磨情况，建议做进一步检查',

  'txt_tire_age_5': '轮胎有效期已超过5年，建议更换轮胎;',
  'txt_tire_age_3': '轮胎有效期已超过3年，请定期进行检查;',
  'txt_tire_age_ok': '轮胎年限良好;',
  'txt_tire_trauma_check': '轮胎面有外伤，建议做进一步检查;',
  'txt_install_abnormal_check': '轮胎装反，请确认处理;',
  'txt_tire_appearance_ok': '轮胎外观良好;',
  'txt_pinnate_check': '轮胎存在羽状磨损，建议动平衡及对四轮定位、悬架系统进行检查;',
  'trauma_lacerate': '割裂',
  'trauma_dropblock': '掉块',
  'trauma_puncture': '穿刺',
  'trauma_scratch': '擦伤',
  'trauma_pinnate': '羽状磨损',
  'txt_tire_age': '轮胎年限',
  'txt_tire appearance': '轮胎外观',
  'tire_installed': '轮胎安装',
  'abnormal': '内外装反',
  'endurance': '轮胎续航里程估算',
  'endurance-warn': '剩余续航里程为{number}miles',
  'endurance-zero': '轮胎已达极限，请立即更换',
  'estimated_value': '预估值',
  'abrasion': '磨损',
  'remanent': '轮胎使用时间剩余{days}天',
  'useful_life': '轮胎使用年限估算',
  'dot_explain': 'DOT：轮胎制造时间',
  'unit_km': '单位：km',
  'unit_year': '单位：年',
  'dot_no': '未获取到生产时间',
  'made_time': '生产时间：{dot}({year}年第{week}周)',
  'exceed_five': '轮胎有效期已超过5年,请立即更换',
  'inspector': '技师',
  'txt_install_check': '安装检查',
  'txt_install_ok': '安装正常',
  'txt_pinnate_wear': '羽状磨损',

  'LFtxt_tire_need_replace': '[左前轮]轮胎严重磨损，建议更换轮胎',
  'RFtxt_tire_need_replace': '[右前轮]轮胎严重磨损，建议更换轮胎',
  'LBtxt_tire_need_replace': '[左后轮]轮胎严重磨损，建议更换轮胎',
  'RBtxt_tire_need_replace': '[右后轮]轮胎严重磨损，建议更换轮胎',
  'FFtxt_tire_need_replace': '[前轴]轮胎严重磨损，建议更换轮胎',
  'BBtxt_tire_need_replace': '[后轴]轮胎严重磨损，建议更换轮胎',


  'txt_pattern_wear': '异常磨损',
  'txt_uneven_center': '轮胎中间磨损异常，建议检查胎压',
  'LFtxt_uneven_center': '[左前轮]轮胎中间磨损异常，建议检查胎压',
  'RFtxt_uneven_center': '[右前轮]轮胎中间磨损异常，建议检查胎压',
  'LBtxt_uneven_center': '[左后轮]轮胎中间磨损异常，建议检查胎压',
  'RBtxt_uneven_center': '[右后轮]轮胎中间磨损异常，建议检查胎压',
  'FFtxt_uneven_center': '[前轴]轮胎中间磨损异常，建议检查胎压',
  'BBtxt_uneven_center': '[后轴]轮胎中间磨损异常，建议检查胎压',

  'txt_uneven_out': '轮胎外侧磨损异常，建议检查四轮定位及胎压',
  'LFtxt_uneven_out': '[左前轮]轮胎外侧磨损异常，建议检查四轮定位及胎压',
  'RFtxt_uneven_out': '[右前轮]轮胎外侧磨损异常，建议检查四轮定位及胎压',
  'LBtxt_uneven_out': '[左后轮]轮胎外侧磨损异常，建议检查四轮定位及胎压',
  'RBtxt_uneven_out': '[右后轮]轮胎外侧磨损异常，建议检查四轮定位及胎压',
  'FFtxt_uneven_out': '[前轴]轮胎外侧磨损异常，建议检查四轮定位及胎压',
  'BBtxt_uneven_out': '[后轴]轮胎外侧磨损异常，建议检查四轮定位及胎压',

  'LFtxt_pinnate_check':'[左前轮]轮胎存在羽状磨损，建议动平衡及对四轮定位、悬架系统进行检查',
  'RFtxt_pinnate_check':'[右前轮]轮胎存在羽状磨损，建议动平衡及对四轮定位、悬架系统进行检查',
  'LBtxt_pinnate_check':'[左后轮]轮胎存在羽状磨损，建议动平衡及对四轮定位、悬架系统进行检查',
  'RBtxt_pinnate_check':'[右后轮]轮胎存在羽状磨损，建议动平衡及对四轮定位、悬架系统进行检查',
  'FFtxt_pinnate_check':'[前轴]轮胎存在羽状磨损，建议动平衡及对四轮定位、悬架系统进行检查',
  'BBtxt_pinnate_check':'[后轴]轮胎存在羽状磨损，建议动平衡及对四轮定位、悬架系统进行检查',

  'txt_uneven_inside': '轮胎内侧磨损异常，建议检查四轮定位及胎压',
  'LFtxt_uneven_inside': '[左前轮]轮胎内侧磨损异常，建议检查四轮定位及胎压',
  'RFtxt_uneven_inside': '[右前轮]轮胎内侧磨损异常，建议检查四轮定位及胎压',
  'LBtxt_uneven_inside': '[左后轮]轮胎内侧磨损异常，建议检查四轮定位及胎压',
  'RBtxt_uneven_inside': '[右后轮]轮胎内侧磨损异常，建议检查四轮定位及胎压',
  'FFtxt_uneven_inside': '[前轴]轮胎内侧磨损异常，建议检查四轮定位及胎压',
  'BBtxt_uneven_inside': '[后轴]轮胎内侧磨损异常，建议检查四轮定位及胎压',

  'FFtxt_uneven_check': '[前轴]轮胎磨损异常，建议动平衡及对四轮定位,悬架系统进行检查',
  'BBtxt_uneven_check': '[后轴]轮胎磨损异常，建议动平衡及对四轮定位,悬架系统进行检查',


  'LFtxt_tire_age_5': '[左前轮]轮胎有效期已超过5年，建议更换轮胎',
  'RFtxt_tire_age_5': '[右前轮]轮胎有效期已超过5年，建议更换轮胎',
  'LBtxt_tire_age_5': '[左后轮]轮胎有效期已超过5年，建议更换轮胎',
  'RBtxt_tire_age_5': '[右后轮]轮胎有效期已超过5年，建议更换轮胎',
  'FFtxt_tire_age_5': '[前轴]轮胎有效期已超过5年，建议更换轮胎',
  'BBtxt_tire_age_5': '[后轴]轮胎有效期已超过5年，建议更换轮胎',

  'txt_appearance_check':'轮胎存在外伤，请维修确认',
  'LFtxt_appearance_check':'[左前轮]轮胎存在外伤，请维修确认',
  'RFtxt_appearance_check':'[右前轮]轮胎存在外伤，请维修确认',
  'LBtxt_appearance_check':'[左后轮]轮胎存在外伤，请维修确认',
  'RBtxt_appearance_check':'[右后轮]轮胎存在外伤，请维修确认',
  'FFtxt_appearance_check':'[前轴]轮轮胎存在外伤，请维修确认',
  'BBtxt_appearance_check':'[后轴]轮胎存在外伤，请维修确认',


  'LFtxt_install_abnormal_check':'[左前轮]轮胎装反，请确认处理',
  'RFtxt_install_abnormal_check':'[右前轮]轮胎装反，请确认处理',
  'LBtxt_install_abnormal_check':'[左后轮]轮胎装反，请确认处理',
  'RBtxt_install_abnormal_check':'[右后轮]轮胎装反，请确认处理',
  'FFtxt_install_abnormal_check':'[前轴]轮胎装反，请确认处理',
  'BBtxt_install_abnormal_check':'[后轴]轮胎装反，请确认处理',

  'txt_uneven_bothside': '轮胎两边磨损异常，建议检查四轮定位及胎压',
  'LFtxt_uneven_bothside': '[左前轮]轮胎两边磨损异常，建议检查四轮定位及胎压',
  'RFtxt_uneven_bothside': '[右前轮]轮胎两边磨损异常，建议检查四轮定位及胎压',
  'LBtxt_uneven_bothside': '[左后轮]轮胎两边磨损异常，建议检查四轮定位及胎压',
  'RBtxt_uneven_bothside': '[右后轮]轮胎两边磨损异常，建议检查四轮定位及胎压',
  'FFtxt_uneven_bothside': '[前轴]轮胎两边磨损异常，建议检查四轮定位及胎压',
  'BBtxt_uneven_bothside': '[后轴]轮胎两边磨损异常，建议检查四轮定位及胎压',

  'txt_wear_lookup': '请检查轮胎磨损情况',
  'LFtxt_wear_lookup': '[左前轮]请检查轮胎磨损情况',
  'RFtxt_wear_lookup': '[右前轮]请检查轮胎磨损情况',
  'LBtxt_wear_lookup': '[左后轮]请检查轮胎磨损情况',
  'RBtxt_wear_lookup': '[右后轮]请检查轮胎磨损情况',
  'FFtxt_wear_lookup': '[前轴]请检查轮胎磨损情况',
  'BBtxt_wear_lookup': '[后轴]请检查轮胎磨损情况',

  'txt_appearance_lookup': '请检查轮胎外观情况',
  'LFtxt_appearance_lookup': '[左前轮]请检查轮胎外观情况',
  'RFtxt_appearance_lookup': '[右前轮]请检查轮胎外观情况',
  'LBtxt_appearance_lookup': '[左后轮]请检查轮胎外观情况',
  'RBtxt_appearance_lookup': '[右后轮]请检查轮胎外观情况',
  'FFtxt_appearance_lookup': '[前轴]请检查轮胎外观情况',
  'BBtxt_appearance_lookup': '[后轴]请检查轮胎外观情况',

  'txt_serious_trauma_check': '轮胎存在严重外伤，建议更换轮胎',
  'LFtxt_serious_trauma_check': '[左前轮]轮胎存在严重外伤，建议更换轮胎',
  'RFtxt_serious_trauma_check': '[右前轮]轮胎存在严重外伤，建议更换轮胎',
  'LBtxt_serious_trauma_check': '[左后轮]轮胎存在严重外伤，建议更换轮胎',
  'RBtxt_serious_trauma_check': '[右后轮]轮胎存在严重外伤，建议更换轮胎',
  'FFtxt_serious_trauma_check': '[前轴]轮胎存在严重外伤，建议更换轮胎',
  'BBtxt_serious_trauma_check': '[后轴]轮胎存在严重外伤，建议更换轮胎',

  'purp_tireservice':'轮胎服务',
  'purp_vehicle_inspection':'车辆检查',
  'merchant': '商户',
  'manager': '店长',
  'assistant': '店员',

  'txt_chassis_check': '底盘检查',
  'txt_chassis_ok': '底盘检查良好',
  'LFtxt_chassis_ok': '[左前轮]底盘检查良好',
  'RFtxt_chassis_ok': '[右前轮]底盘检查良好',
  'LBtxt_chassis_ok': '[左后轮]底盘检查良好',
  'RBtxt_chassis_ok': '[右后轮]底盘检查良好',
  'FFtxt_chassis_ok': '[前轴]底盘检查良好',
  'BBtxt_chassis_ok': '[后轴]底盘检查良好',
  'txt_chassis_oil': '减震器漏油，建议做进一步确认，必要时请更换处理',
  'LFtxt_chassis_oil': '[左前轮]减震器漏油，建议做进一步确认，必要时请更换处理',
  'RFtxt_chassis_oil': '[右前轮]减震器漏油，建议做进一步确认，必要时请更换处理',
  'LBtxt_chassis_oil': '[左后轮]减震器漏油，建议做进一步确认，必要时请更换处理',
  'RBtxt_chassis_oil': '[右后轮]减震器漏油，建议做进一步确认，必要时请更换处理',
  'FFtxt_chassis_oil': '[前轴]减震器漏油，建议做进一步确认，必要时请更换处理',
  'BBtxt_chassis_oil': '[后轴]减震器漏油，建议做进一步确认，必要时请更换处理',
  'txt_chassis_aging': '轴头橡胶老化，建议进行更换处理',
  'LFtxt_chassis_aging': '[左前轮]轴头橡胶老化，建议进行更换处理',
  'RFtxt_chassis_aging': '[右前轮]轴头橡胶老化，建议进行更换处理',
  'LBtxt_chassis_aging': '[左后轮]轴头橡胶老化，建议进行更换处理',
  'RBtxt_chassis_aging': '[右后轮]轴头橡胶老化，建议进行更换处理',
  'FFtxt_chassis_aging': '[前轴]轴头橡胶老化，建议进行更换处理',
  'BBtxt_chassis_aging': '[后轴]轴头橡胶老化，建议进行更换处理',
  'awd': 'APP下载',

  'depth': '沟槽深度',
  'usura': '偏磨',
  'usura-l': '花纹磨损均匀',
  'usura-m': '轻微偏磨',
  'usura-h': '严重偏磨',

  'engine-hood': '发动机罩',
  'change-control': '改变控制',
  'subtotal': '小计',

  'replace': '更换',
  'vedi': '查阅注释',
  'ok': '正常',

  'liquid': '洗涤液',
  'level-control': '级别控制',
  'air': '空调系统',
  'last-reload': '上次加载',
  'absorber': '减震器',
  'check-loss': '检查可见损失',
  'fluid': '散热器流体',
  'lighting': '灯光',
  'verification': '核查运作',
  'disc': '制动盘',
  'condition': '磨损状态',
  'oil': '发动机油',
  'wiper': '雨刮器',
  'pads': '刹车片',
  'brake-fluid': '刹车液体',
  'quality-control': '质量控制',
  'battery': '电池',
  'multimetro': '万用表测试',
   'control-quality': '质量控制',
   'tightening': '测功机拧紧',
  'buone': '正常',
  'lavoro': '工作排程',
  'concordare': '允许替换',
  'season_summer': '夏季胎',
  'season_winter': '冬季胎',

  'trauma_eattyre': '吃胎',
  'txt_uneven_eattyre': '轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LFtxt_uneven_eattyre': '[左前轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RFtxt_uneven_eattyre': '[右前轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LBtxt_uneven_eattyre': '[左后轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RBtxt_uneven_eattyre': '[右后轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'FFtxt_uneven_eattyre': '[前轴]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'BBtxt_uneven_eattyre': '[后轴]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',


  'txt_appearance_eattyre': '轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LFtxt_appearance_eattyre': '[左前轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RFtxt_appearance_eattyre': '[右前轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LBtxt_appearance_eattyre': '[左后轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RBtxt_appearance_eattyre': '[右后轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'FFtxt_appearance_eattyre': '[前轴]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'BBtxt_appearance_eattyre': '[后轴]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',


  'txt_chassis_eattyre': '轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'LFtxt_chassis_eattyre': '[左前轮]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'RFtxt_chassis_eattyre': '[右前轮]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'LBtxt_chassis_eattyre': '[左后轮]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'RBtxt_chassis_eattyre': '[右后轮]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'FFtxt_chassis_eattyre': '[前轴]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'BBtxt_chassis_eattyre': '[后轴]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',

  'txt_uneven_high_eattyre':'轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'LFtxt_uneven_high_eattyre': '[左前轮]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'RFtxt_uneven_high_eattyre': '[右前轮]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'LBtxt_uneven_high_eattyre': '[左后轮]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'RBtxt_uneven_high_eattyre': '[右后轮]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'FFtxt_uneven_high_eattyre': '[前轴]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'BBtxt_uneven_high_eattyre': '[后轴]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',


  'txt_appearance_high_eattyre':'轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LFtxt_appearance_high_eattyre': '[左前轮]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RFtxt_appearance_high_eattyre': '[右前轮]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LBtxt_appearance_high_eattyre': '[左后轮]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RBtxt_appearance_high_eattyre': '[右后轮]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'FFtxt_appearance_high_eattyre': '[前轴]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'BBtxt_appearance_high_eattyre': '[后轴]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',

  'txt_chassis_high_eattyre':'轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'LFtxt_chassis_high_eattyre': '[左前轮]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'RFtxt_chassis_high_eattyre': '[右前轮]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'LBtxt_chassis_high_eattyre': '[左后轮]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'RBtxt_chassis_high_eattyre': '[右后轮]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'FFtxt_chassis_high_eattyre': '[前轴]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'BBtxt_chassis_high_eattyre': '[后轴]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',

  'txt_chassis_uneven': '轮胎异常偏磨，建议对四轮定位，悬挂系统进行检查',

  'com':'商用车',
  'pass':'乘用车',

    // 大车翻译
  'truck': '拖车',
  'mount': '挂车',
  'left': '左',
  'right': '右',
  'undevice': '未检测',
  'axle': '轴',
  'spare': '备胎',
  'nodata': '暂无数据',



}
