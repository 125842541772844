import React, { Component } from 'react';
import "./css/Cormach-header.scss"
import intl from 'react-intl-universal';
class Cormach_header extends Component{
    constructor(props) {
        super(props);
        this.state ={}
    }


    render() {
               // 时间格式化
            const  formatDate = (date, fmt) => {
                var currentDate = new Date(date);
                var o = {
                    "M+": currentDate.getMonth() + 1, //月份
                    "d+": currentDate.getDate(), //日
                    "h+": currentDate.getHours(), //小时
                    "m+": currentDate.getMinutes(), //分
                    "s+": currentDate.getSeconds(), //秒
                    "q+": Math.floor((currentDate.getMonth() + 3) / 3), //季度
                    "S": currentDate.getMilliseconds() //毫秒
                };
                if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (currentDate.getFullYear() + "").substr(4 - RegExp.$1.length));
                for (var k in o)
                    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
                return fmt;
            };
        let {logo, store_name, store_address, device_time, plate, purpose, checker, odometer, remark} = this.props.data
        return (
            <div>
              <img src={logo ? logo : require("../assets/logo.png")} alt="" style={{width: 186, objectFit: "contain"}} />
              <div className="header-store">
                <p><span style={{marginRight:15}}>{intl.get('store')}:</span><span>{store_name}</span></p>
                <p><span style={{marginRight:15}}>{intl.get('address')}:</span><span>{store_address}</span></p>
              </div>
             <div className="header-device">
                 <div className='item'>
                     <span className="tcl">{intl.get('plate_no')}</span><span className="ccl">{plate}</span>
                 </div>
                 <div className='item'>
                     <span className="tcl">{intl.get('purpose')}</span><span className="ccl">{intl.get(purpose)}</span>
                 </div>
                 <div className='item'>
                    <span className="tcl">{intl.get('device_time')}</span ><span className="ccl">{formatDate(device_time, 'dd/MM/yyyy hh:mm:ss')}</span>
                 </div>
                   <div className='item'>
                    <span className="tcl">{intl.get('inspector')}</span><span className="ccl">{checker}</span>
                 </div>
                  <div className='item'>
                    <span className="tcl">{intl.get('mileage')}</span ><span className="ccl">{odometer ? odometer + "km" : " "}</span>
                 </div>
                    <div className='item'>
                    <span className="tcl">{intl.get('remark')}</span ><span className="ccl">{remark}</span>
                 </div>
              </div>

             </div>

        )
    }

}

export default Cormach_header;