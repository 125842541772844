import React, {Component} from "react";
import "./LorList.scss"
import {Button, Layout, message, Table} from "antd";
import intl from "react-intl-universal";
import {Link} from "react-router-dom";

const {Header, Content,} = Layout;


class LorList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            listColumns: [
                {title: intl.get('number'), dataIndex: 'id'},
                {
                    title: intl.get('device_time'), dataIndex: 'device_time',
                    render: (device_time) => (
                        <span>{device_time.replace('T', ' ')}</span>
                    )
                },
                {title: intl.get('plate_no'), dataIndex: 'plate_no'},
                {title: intl.get('car_type'), dataIndex: 'car_type',
                   render: (car_type) => (
                       <span>{this.returnCarType(car_type)}</span>
                    )},
                {
                    title: intl.get('car_type'), dataIndex: 'car',
                    render: (car) => (
                       <span>{this.returnType(car)}</span>
                    )
                },
                {
                    title: intl.get('operation'), key: 'actions',
                    render: ({id}) => (
                        <Link to={`/lordetail/${id}`}>{intl.get('view')}</Link>

                    ),
                },
            ],
            loading: false,
            pagination: {},
            rep_time: ''

        }
    };

    returnType(car){
        let result = intl.options.currentLocale=='zh'? car.zh:car.en;
        return result
    };

    // 返回类型
    returnCarType(type){
        switch (type) {
            case 'truck':
                return intl.get('truck');
            case 'mount':
                return intl.get('mount');
        }
    };

    componentDidMount() {
        this.getReports()
    }

    // 获取报告

    getReports(params = {page: 1, device_time: this.rep_time}) {
        this.setState({loading: true});
        global.Api.getLorList({
            qs: '',
            ...params
        }).then(res => {
            if (res.data.code === 0) {
                const pagination = {...this.state.pagination};
                let _data = res.data.data;
                pagination.total = _data.total;
                pagination.pageSize = 15;
                this.setState({
                    loading: false,
                    list: _data.reports,
                    pagination,
                });
                return
            }
            message.error(res.data.message);

        })
    }


    // 翻页
    handleTableChange = (pagination) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.getReports({
            page: pagination.current,
            per_page: pagination.pageSize,
            device_time: this.rep_time
        });
    };

    render() {
        return (
            <Layout className="lorlist">
                <Header style={{position: 'fixed', zIndex: 1, width: '100%', color: "#fff"}}>
                     <Button type="link" style={{marginRight:"150px"}} ><Link to="/reports">{intl.get('pass')}</Link></Button>
                </Header>
                <Content style={{padding: '0 50px', marginTop: 84}}>
                    <div style={{background: '#fff', padding: 24}}>
                        <Table
                            rowKey={item => item.id}
                            dataSource={this.state.list}
                            columns={this.state.listColumns}
                            pagination={this.state.pagination}
                            loading={this.state.loading}
                            onChange={this.handleTableChange}/>
                    </div>
                </Content>
            </Layout>
        )
    }

}

export default LorList