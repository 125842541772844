import React, {Component} from "react";
import "./css/CormachWear.scss"
import Title from "./Title";
import intl from "react-intl-universal";
import CormachWearCar from "./CormachWearCar";
import UnitWear from "./UnitWear";
class CormachWear extends Component{
    constructor(props) {
        super(props);
        this.state={
        }
    }



    render() {
        let {tyre_lb, tyre_lf, tyre_rb, tyre_rf, wear_threshold, wear_lf_data, wear_rf_data, wear_lb_data, wear_rb_data, ecc_wear_lf_pos, ecc_wear_rf_pos, ecc_wear_lb_pos, ecc_wear_rb_pos, ecc_wear_lf_degree, ecc_wear_rf_degree, ecc_wear_lb_degree, ecc_wear_rb_degree, season} = this.props.data;

        return (
            <div className="wear">
               <div className="wear-one">
                  <Title content="Usura & Usura parziale"/>
               </div>
               <div className="wear-two">
                   <img  src={require("../assets/lt_wco.png")} alt=''/>
               </div>
               <div className="wear-three">
                   <div className="wear-lf">
                       <UnitWear data={{pos:intl.get('lf'), wear_threshold: wear_threshold, wear_data:wear_lf_data, ecc_degree:ecc_wear_lf_degree, ecc_pos:ecc_wear_lf_pos, brand:tyre_lf.brand, spec:tyre_lf.spec, season: season.LF}}/>
                   </div>
                   <div className="wear-rf">
                       <UnitWear data={{pos:intl.get('rf'), wear_threshold: wear_threshold, wear_data:wear_rf_data, ecc_degree:ecc_wear_rf_degree, ecc_pos:ecc_wear_rf_pos, brand:tyre_rf.brand, spec:tyre_rf.spec, season: season.RF}}/>
                   </div>
                   <div className="wear-cen">
                       <CormachWearCar />
                   </div>
                   <div className="wear-lb">
                       <UnitWear data={{pos:intl.get('lr'), wear_threshold: wear_threshold, wear_data:wear_lb_data, ecc_degree:ecc_wear_lb_degree, ecc_pos:ecc_wear_lb_pos, brand:tyre_lb.brand, spec:tyre_lb.spec, season: season.LB}}/>
                   </div>
                   <div className="wear-rb">
                       <UnitWear data={{pos:intl.get('rr'), wear_threshold: wear_threshold, wear_data:wear_rb_data, ecc_degree:ecc_wear_rb_degree, ecc_pos:ecc_wear_rb_pos, brand:tyre_rb.brand, spec:tyre_rb.spec, season: season.RB}}/>
                   </div>

               </div>
            </div>
        )
    }

}

export default CormachWear;