export default {
    'login': 'Masuk',
    'input_name': 'Sila masukkan nama pengguna',
    'input_password': 'Sila masukkan kata laluan anda',
    'login_su': 'Berjaya mendaftar masuk',
    'role':'peran',
   'username':'nama pengguna',
   'password':'Katalaluan',

    'store': 'Kedai',
    'number': 'Nombor',
    'device_time': 'Masa',
    'wear_co': 'Keadaan Haus',
    'ecc_co': 'Bias pengisaran',
    'plate_no': 'Lesen',
    'cus_ph': 'Pelanggan telefon',
    'car_type': 'Model',
    'operation': 'Operasi',
    'view': 'Butiran',
    'cus_email': 'Emel pelanggan',
    'cus_name': 'Nama pelanggan',


    'print': 'Cetakan',
    'store_name': 'Kedai',
    'address': 'Alamat kedai',
    'model': 'Model',
    'mileage': 'Jarak Tempuh',
    'device': 'Peranti',
    'remark': 'Perkataan',
    'purpose': 'Tujuan',
    're': 'Cadangan Pemeriksaan',
    'color_flag': 'Penerangan Warna',
    'wear': 'Memakai',
    'brake': 'Peringatan Kadar Brek Semasa',
    'ecc': 'Tidak Sekata',
    'lf': 'L-F',
    'lr': 'L-R',
    'rf': 'R-F',
    'rr': 'R-R',
    'untest': 'Tayar tidak dikesan',
    'low': 'Normal',
    'middle': 'Sempadan',
    'high': 'Ganti',
    'avg': 'Purata',
    'spec': 'Saiz',
    'brand': 'Jenama',
    'ecc_avg': 'Haus seragam',
    'trauma': 'Trauma Tayar',
    'zc': 'Normal',
    'image': 'zh',
    'qr': 'Imbas Kod QR untuk melihat Laporan',
    'model_number': 'Pengesan Tayar Tangan',
    'purp_wash': 'Kereta basuh Kecantikan',
    'purp_alignment': 'Kedudukan empat roda',
    'purp_replace': 'Menukar tayar',
    'purp_maintain': 'Pembaikan dan penyelenggaraan',
    'warn': 'Peringatan: bergantung pada keadaan haus tayar semasa, jarak brek boleh meningkatkan',
    'trauma_normal': 'Normal',
    'trauma_bulge': 'Bonjolan',
    'trauma_aging': 'Penuaan',

    'txt_lf_outworn': 'Luaran tayar depan kiri dipakai daripada dalaman',
    'txt_lf_innerworn': 'Dalaman tayar hadapan kiri dipakai daripada di luar',
    'txt_lf_bothsideworn': 'Kedua-dua belah tayar hadapan kiri adalah dipakai dari pusat',
    'txt_lf_centreworn': 'Pusat tayar hadapan kiri dipakai daripada kedua-dua belah',
    'txt_lb_outworn': 'Yang luar sebelah kiri tayar belakang dipakai daripada dalaman',
    'txt_lb_innerworn': 'Bahagian dalam tayar belakang kiri dipakai daripada luar',
    'txt_lb_bothsideworn': 'Kedua-dua belah tayar belakang kiri adalah dipakai dari pusat',
    'txt_lb_centreworn': 'Pusat tayar belakang kiri dipakai daripada kedua-dua belah',
    'txt_rb_outworn': 'Bahagian luar belakang kanan dipakai daripada dalaman',
    'txt_rb_innerworn': 'Bahagian dalam bahagian belakang kanan dipakai daripada luar',
    'txt_rb_bothsideworn': 'Kedua-dua belah bahagian belakang kanan adalah dipakai dari pusat',
    'txt_rb_centreworn': 'Pusat belakang kanan dipakai daripada kedua-dua belah',
    'txt_rf_outworn': 'Di luar tayar hadapan kanan dipakai daripada dalaman',
    'txt_rf_innerworn': 'Dalaman tayar hadapan kanan dipakai daripada luar',
    'txt_rf_bothsideworn': 'Kedua-dua belah tayar hadapan kanan adalah dipakai dari pusat',
    'txt_rf_centreworn': 'Pusat tayar hadapan kanan dipakai daripada kedua-dua belah pihak',

    'txt_tire_need_replace': 'Status pakaian tayar: Gant',
    'txt_tire_boundary': 'Status pakaian tayar: Sempadan',
    'txt_alignment_chassis': 'Penjajaran, dan semakan casis adalah disyorkan',
    'txt_wear_uniform': 'Memakai tayar adalah seragam',
    'txt_do_further_check': 'Sila semak selanjutnya',
    'txt_uneven_check': 'Tayar mempunyai memakai yang tidak sekata, sila lakukan semakan lanjut',
    'txt_tire_rotation': 'Mencadangkan putaran tayar',
    'txt_wear_normal': 'Memakai tayar adalah normal',
    'txt_tire': 'Tayar',

    'time': 'Sila pilih satu masa',
    'today': 'Hari ini',
    'yesterday': 'Semalam',
    'week': 'Minggu ini',
    'month': 'Bulan ini',
    'last_month': 'Bulan lepas',
    'year': 'Tahun ini',
    'language': 'Sila pilih Bahasa',
    'zh': 'Cina',
    'en': 'Inggeris',
    'ma': 'Melayu',
    'ge': 'Jerman',
    'fr': 'Perancis',
    'lt': 'Itali',
    'sp': 'Sepanyol',
    'tw':'Cina Tradisional.',
     'jp':'Jepun',
    'po':'poland',
    'por':'Portugis',
    'download': 'Muat Turun Laporan',
    'advise': 'Cadangan:Tayar mempunyai memakai yang tidak sekata, sila lakukan semakan lanjut',


    'txt_tire_age_5': 'Tayar telah digunakan untuk lebih daripada 5 tahun dan pemeriksaan yang menyeluruh tayar adalah disyorkan;',
    'txt_tire_age_3': 'Tayar telah digunakan selama lebih daripada 3 tahun dan pemeriksaan yang menyeluruh tayar adalah disyorkan;',
    'txt_tire_age_ok': 'Kehidupan tayar biasa;',
    'txt_tire_trauma_check': 'Permukaan tayar mempunyai trauma, peperiksaan lanjut adalah disyorkan;',
    'txt_install_abnormal_check': 'Pemasangan tayar tidak normal, adalah disyorkan untuk melakukan pemeriksaan lanjut;',
    'txt_tire_appearance_ok': 'Tayar berada dalam keadaan baik;',
    'txt_pinnate_check': 'Ada pakaian bulu pada tayar. Ia disarankan untuk memeriksa lebih lanjut bahagian chasis;',
    'trauma_lacerate': 'Berasingan',
    'trauma_dropblock': 'Jatuh',
    'trauma_puncture': 'Tebuk',
    'trauma_scratch': 'Abrasions',
    'trauma_pinnate': 'Pakaian pinggang',
    'txt_tire_age': 'Hayat tayar',
    'txt_tire appearance': 'Penampilan tayar',
    'tire_installed': 'Tayar pemasangan',
    'abnormal': 'Songsang dalam dan luar',
    'endurance': 'Anggaran pelbagai tayar',
    'endurance-warn': 'Kekal yang tersisa adalah {number}km',
    'endurance-zero': 'Tayar telah mencapai had, sila gantikannya segera',
    'estimated_value': 'Anggaran',
    'abrasion': 'Memakai',
    'remanent': '{days} hari yang tinggal untuk hayat tayar.',
    'useful_life': 'Anggaran hayat tayar.',
    'dot_explain': 'DOT：Tayar masa pembuatan.',
    'unit_km': 'Unit：km',
    'unit_year': 'Unit：tahun',
    'dot_no': 'Masa produksi tidak diperoleh',
    'made_time': 'Masa produksi: {year} ({year} tahun {week} minggu)',
    'exceed_five': 'Kehidupan tayar telah lebih daripada 5 tahun, menggantikannya sekarang.',
    'inspector': 'Juruteknik',
    'txt_install_check': 'Pemeriksaan pemasangan',
    'txt_install_ok': 'Pemasangan biasa',
    'txt_pinnate_wear': 'Pakaian pinggang',






  'LFtxt_tire_need_replace': '[L-F]Status pakaian tayar: Gant',
  'RFtxt_tire_need_replace': '[R-F]Status pakaian tayar: Gant',
  'LBtxt_tire_need_replace': '[L-R]Status pakaian tayar: Gant',
  'RBtxt_tire_need_replace': '[R-R]Status pakaian tayar: Gant',
  'FFtxt_tire_need_replace': '[F-F]Status pakaian tayar: Gant',
  'BBtxt_tire_need_replace': '[B-B]Status pakaian tayar: Gant',


  'txt_pattern_wear': 'Uneven',
  'txt_uneven_center': 'Pakaian tidak normal di tengah-tengah tayar. Ia disarankan untuk memeriksa tekanan tayar',
  'LFtxt_uneven_center': '[L-F]Pakaian tidak normal di tengah-tengah tayar. Ia disarankan untuk memeriksa tekanan tayar',
  'RFtxt_uneven_center': '[R-F]Pakaian tidak normal di tengah-tengah tayar. Ia disarankan untuk memeriksa tekanan tayar',
  'LBtxt_uneven_center': '[L-R]Pakaian tidak normal di tengah-tengah tayar. Ia disarankan untuk memeriksa tekanan tayar',
  'RBtxt_uneven_center': '[R-R]Pakaian tidak normal di tengah-tengah tayar. Ia disarankan untuk memeriksa tekanan tayar',
  'FFtxt_uneven_center': '[F-F]Pakaian tidak normal di tengah-tengah tayar. Ia disarankan untuk memeriksa tekanan tayar',
  'BBtxt_uneven_center': '[B-B]Pakaian tidak normal di tengah-tengah tayar. Ia disarankan untuk memeriksa tekanan tayar',

  'txt_uneven_out': 'Sisi luar tayar dipakai secara tidak normal. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar',
  'LFtxt_uneven_out': '[L-F]Sisi luar tayar dipakai secara tidak normal. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar',
  'RFtxt_uneven_out': '[R-F]Sisi luar tayar dipakai secara tidak normal. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar',
  'LBtxt_uneven_out': '[L-R]Sisi luar tayar dipakai secara tidak normal. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar',
  'RBtxt_uneven_out': '[R-R]Sisi luar tayar dipakai secara tidak normal. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar',
  'FFtxt_uneven_out': '[F-F]Sisi luar tayar dipakai secara tidak normal. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar',
  'BBtxt_uneven_out': '[B-B]Sisi luar tayar dipakai secara tidak normal. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar',

  'LFtxt_pinnate_check':'[L-F]Ada pakaian bulu pada tayar. Ia disarankan untuk memeriksa lebih lanjut bahagian chasis;',
  'RFtxt_pinnate_check':'[R-F]Ada pakaian bulu pada tayar. Ia disarankan untuk memeriksa lebih lanjut bahagian chasis;',
  'LBtxt_pinnate_check':'[L-R]Ada pakaian bulu pada tayar. Ia disarankan untuk memeriksa lebih lanjut bahagian chasis;',
  'RBtxt_pinnate_check':'[R-R]Ada pakaian bulu pada tayar. Ia disarankan untuk memeriksa lebih lanjut bahagian chasis;',
  'FFtxt_pinnate_check':'[F-F]Ada pakaian bulu pada tayar. Ia disarankan untuk memeriksa lebih lanjut bahagian chasis;',
  'BBtxt_pinnate_check':'[B-B]Ada pakaian bulu pada tayar. Ia disarankan untuk memeriksa lebih lanjut bahagian chasis;',

  'txt_uneven_inside': 'Sisi dalaman tayar dipakai secara tidak normal. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar',
  'LFtxt_uneven_inside': '[L-F]Sisi dalaman tayar dipakai secara tidak normal. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar',
  'RFtxt_uneven_inside': '[R-F]Sisi dalaman tayar dipakai secara tidak normal. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar',
  'LBtxt_uneven_inside': '[L-R]Sisi dalaman tayar dipakai secara tidak normal. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar',
  'RBtxt_uneven_inside': '[R-R]Sisi dalaman tayar dipakai secara tidak normal. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar',
  'FFtxt_uneven_inside': '[F-F]Sisi dalaman tayar dipakai secara tidak normal. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar',
  'BBtxt_uneven_inside': '[B-B]Sisi dalaman tayar dipakai secara tidak normal. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar',

  'FFtxt_uneven_check': '[F-F]Tayar mempunyai memakai yang tidak sekata, sila lakukan semakan lanjut',
  'BBtxt_uneven_check': '[B-B]Tayar mempunyai memakai yang tidak sekata, sila lakukan semakan lanjut',


  'LFtxt_tire_age_5': '[L-F]Tayar telah digunakan untuk lebih daripada 5 tahun dan pemeriksaan yang menyeluruh tayar adalah disyorkan;',
  'RFtxt_tire_age_5': '[R-F]Tayar telah digunakan untuk lebih daripada 5 tahun dan pemeriksaan yang menyeluruh tayar adalah disyorkan;',
  'LBtxt_tire_age_5': '[L-R]Tayar telah digunakan untuk lebih daripada 5 tahun dan pemeriksaan yang menyeluruh tayar adalah disyorkan;',
  'RBtxt_tire_age_5': '[R-R]Tayar telah digunakan untuk lebih daripada 5 tahun dan pemeriksaan yang menyeluruh tayar adalah disyorkan;',
  'FFtxt_tire_age_5': '[F-F]Tayar telah digunakan untuk lebih daripada 5 tahun dan pemeriksaan yang menyeluruh tayar adalah disyorkan;',
  'BBtxt_tire_age_5':  '[B-B]Tayar telah digunakan untuk lebih daripada 5 tahun dan pemeriksaan yang menyeluruh tayar adalah disyorkan;',

  'txt_appearance_check':'Penampilan ban tidak normal, pemeriksaan lanjut disarankan',
  'LFtxt_appearance_check':'[L-F]Penampilan ban tidak normal, pemeriksaan lanjut disarankan',
  'RFtxt_appearance_check':'[R-F]Penampilan ban tidak normal, pemeriksaan lanjut disarankan',
  'LBtxt_appearance_check':'[L-R]Penampilan ban tidak normal, pemeriksaan lanjut disarankan',
  'RBtxt_appearance_check':'[R-R]Penampilan ban tidak normal, pemeriksaan lanjut disarankan',
  'FFtxt_appearance_check':'[F-F]Penampilan ban tidak normal, pemeriksaan lanjut disarankan',
  'BBtxt_appearance_check':'[B-B]Penampilan ban tidak normal, pemeriksaan lanjut disarankan',


  'LFtxt_install_abnormal_check':'[L-F]Pemasangan tayar tidak normal, adalah disyorkan untuk melakukan pemeriksaan lanjut.',
  'RFtxt_install_abnormal_check':'[R-F]Pemasangan tayar tidak normal, adalah disyorkan untuk melakukan pemeriksaan lanjut.',
  'LBtxt_install_abnormal_check':'[L-R]Pemasangan tayar tidak normal, adalah disyorkan untuk melakukan pemeriksaan lanjut.',
  'RBtxt_install_abnormal_check':'[R-R]Pemasangan tayar tidak normal, adalah disyorkan untuk melakukan pemeriksaan lanjut.',
  'FFtxt_install_abnormal_check':'[F-F]Pemasangan tayar tidak normal, adalah disyorkan untuk melakukan pemeriksaan lanjut.',
  'BBtxt_install_abnormal_check':'[B-B]Pemasangan tayar tidak normal, adalah disyorkan untuk melakukan pemeriksaan lanjut.',

  'txt_uneven_bothside': 'Pakaian tidak normal pada kedua-dua sisi tayar. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar.',
  'LFtxt_uneven_bothside': '[L-F]Pakaian tidak normal pada kedua-dua sisi tayar. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar.',
  'RFtxt_uneven_bothside': '[R-F]Pakaian tidak normal pada kedua-dua sisi tayar. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar.',
  'LBtxt_uneven_bothside': '[L-R]Pakaian tidak normal pada kedua-dua sisi tayar. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar.',
  'RBtxt_uneven_bothside': '[R-R]Pakaian tidak normal pada kedua-dua sisi tayar. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar.',
  'FFtxt_uneven_bothside': '[F-F]Pakaian tidak normal pada kedua-dua sisi tayar. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar.',
  'BBtxt_uneven_bothside': '[B-B]Pakaian tidak normal pada kedua-dua sisi tayar. Ia disarankan untuk memeriksa jajaran empat roda dan tekanan tayar.',


  'txt_wear_lookup': 'Sila semak pakaian ban',
  'LFtxt_wear_lookup': '[L-F]Sila semak pakaian ban',
  'RFtxt_wear_lookup': '[R-F]Sila semak pakaian ban',
  'LBtxt_wear_lookup': '[L-B]Sila semak pakaian ban',
  'RBtxt_wear_lookup': '[R-B]Sila semak pakaian ban',
  'FFtxt_wear_lookup': '[F-F]Sila semak pakaian ban',
  'BBtxt_wear_lookup': '[B-B]Sila semak pakaian ban',

  'txt_appearance_lookup': 'Sila semak penampilan ban',
  'LFtxt_appearance_lookup': '[L-F]Sila semak penampilan ban',
  'RFtxt_appearance_lookup': '[R-F]Sila semak penampilan ban',
  'LBtxt_appearance_lookup': '[L-B]Sila semak penampilan ban',
  'RBtxt_appearance_lookup': '[R-B]Sila semak penampilan ban',
  'FFtxt_appearance_lookup': '[F-F]Sila semak penampilan ban',
  'BBtxt_appearance_lookup': '[B-B]Sila semak penampilan ban',

  'txt_serious_trauma_check': 'Ban mempunyai cedera yang serius. Ia dicadangkan untuk menggantikan ban',
  'LFtxt_serious_trauma_check': '[L-F]Ban mempunyai cedera yang serius. Ia dicadangkan untuk menggantikan ban',
  'RFtxt_serious_trauma_check': '[R-F]Ban mempunyai cedera yang serius. Ia dicadangkan untuk menggantikan ban',
  'LBtxt_serious_trauma_check': '[L-B]Ban mempunyai cedera yang serius. Ia dicadangkan untuk menggantikan ban',
  'RBtxt_serious_trauma_check': '[R-B]Ban mempunyai cedera yang serius. Ia dicadangkan untuk menggantikan ban',
  'FFtxt_serious_trauma_check': '[F-F]Ban mempunyai cedera yang serius. Ia dicadangkan untuk menggantikan ban',
  'BBtxt_serious_trauma_check': '[B-B]Ban mempunyai cedera yang serius. Ia dicadangkan untuk menggantikan ban',

   'purp_tireservice':'Perkhidmatan ban',
   'purp_vehicle_inspection':'Inspección de vehículos',
   'merchant': 'Penjual',
   'manager': 'Pemilik kedai',
   'assistant': 'Pegawai',

  'txt_chassis_check': 'Pemeriksaan Chassis',
  'txt_chassis_ok': 'Chasis dalam keadaan baik.',
  'LFtxt_chassis_ok': '[L-F]Chasis dalam keadaan baik.',
  'RFtxt_chassis_ok': '[R-F]Chasis dalam keadaan baik.',
  'LBtxt_chassis_ok': '[L-B]Chasis dalam keadaan baik.',
  'RBtxt_chassis_ok': '[R-R]Chasis dalam keadaan baik.',
  'FFtxt_chassis_ok': '[F-F]Chasis dalam keadaan baik.',
  'BBtxt_chassis_ok': '[B-B]Chasis dalam keadaan baik.',
  'txt_chassis_oil': 'Penyerap kejutan mempunyai kebocoran minyak. Ia dicadangkan untuk membuat pengesahan lanjut dan menggantikannya jika perlu.',
  'LFtxt_chassis_oil': '[L-F]Penyerap kejutan mempunyai kebocoran minyak. Ia dicadangkan untuk membuat pengesahan lanjut dan menggantikannya jika perlu.',
  'RFtxt_chassis_oil': '[R-F]Penyerap kejutan mempunyai kebocoran minyak. Ia dicadangkan untuk membuat pengesahan lanjut dan menggantikannya jika perlu.',
  'LBtxt_chassis_oil': '[L-B]Penyerap kejutan mempunyai kebocoran minyak. Ia dicadangkan untuk membuat pengesahan lanjut dan menggantikannya jika perlu.',
  'RBtxt_chassis_oil': '[R-R]Penyerap kejutan mempunyai kebocoran minyak. Ia dicadangkan untuk membuat pengesahan lanjut dan menggantikannya jika perlu.',
  'FFtxt_chassis_oil': '[F-F]Penyerap kejutan mempunyai kebocoran minyak. Ia dicadangkan untuk membuat pengesahan lanjut dan menggantikannya jika perlu.',
  'BBtxt_chassis_oil': '[B-B]Penyerap kejutan mempunyai kebocoran minyak. Ia dicadangkan untuk membuat pengesahan lanjut dan menggantikannya jika perlu.',
  'txt_chassis_aging': 'Gum kepala shaft sudah tua, jadi ia dicadangkan untuk menggantikannya.',
  'LFtxt_chassis_aging': '[L-F]Gum kepala shaft sudah tua, jadi ia dicadangkan untuk menggantikannya.',
  'RFtxt_chassis_aging': '[R-F]Gum kepala shaft sudah tua, jadi ia dicadangkan untuk menggantikannya.',
  'LBtxt_chassis_aging': '[L-B]Gum kepala shaft sudah tua, jadi ia dicadangkan untuk menggantikannya.',
  'RBtxt_chassis_aging': '[R-R]Gum kepala shaft sudah tua, jadi ia dicadangkan untuk menggantikannya.',
  'FFtxt_chassis_aging': '[F-F]Gum kepala shaft sudah tua, jadi ia dicadangkan untuk menggantikannya.',
  'BBtxt_chassis_aging': '[B-B]Gum kepala shaft sudah tua, jadi ia dicadangkan untuk menggantikannya.',
  'awd': 'App Download',

  'depth': 'Trench Depth',
    'usura': 'Eccentric Wear',
    'usura-l': 'The pattern is worn evenly',
    'usura-m': 'Slight eccentric wear',
    'usura-h': 'Severe eccentric wear',

    'engine-hood': 'ENGINE BONNET',
    'change-control': 'CHANGE CONTROL',
    'subtotal': 'UNDERBODY',

    'replace': 'REPLACE',
    'vedi': 'READ NOTE',
    'ok': 'OK',

    'liquid': 'GLASS WASHING LIQUID',
    'level-control': 'Level control',
    'air': 'AIR CONDITIONER',
    'last-reload': 'Last recharge',
    'absorber': 'SHOCK ABSORBER',
    'check-loss': 'Check of visible leaks',
    'fluid': 'RADIATOR FLUID',
    'lighting': 'LIGHTS',
    'verification': 'Opeation check',
    'disc': 'BRAKE DISCS',
    'condition': 'Wearing',
    'oil': 'MOTOR OI',
    'wiper': 'WIPERS',
    'pads': 'BRAKE PADS',
    'brake-fluid': 'BRAKE FLUID',
    'quality-control': 'Quality check',
    'battery': 'BATTERY',
    'multimetro': 'Multimetro test',
    'control-quality': 'QUALITY CHECK',
    'tightening': 'Dynamometer tightening',
    'buone': 'ok',
    'lavoro': 'Work scheduling',
    'concordare': 'Allow substitution',


    'com':'Commercial',
    'pass':'Passenger',

}
