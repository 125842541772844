import React, {Component} from "react";
import "../css/Lorry/SingleTyre.scss"
import intl from "react-intl-universal";

class SingleTyre extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    };

    render() {

        let {lf, lb} = this.props.data;

        let returnColor = (degree) => {
            switch (degree) {
                case 'high':
                    return {url: require("../../assets/lorry/r-c.png"), color: "#FF5230", text: intl.get('high')};
                case 'low' || 'middle':
                    return {url: require("../../assets/lorry/y-c.png"), color: "#36B37E", text: intl.get('low')};
                default:
                    return {url: require("../../assets/lorry/hui-c.png"), color: "#c2c2c2", text: intl.get('undevice')};
            }

        };
         let lang = intl.options.currentLocale;

        return (
            <div className="ts">
                <span className="ts-title">{lf.position.slice(0,1)==='B' ? intl.get('spare') : lf.position.slice(1,2) + intl.get('axle')}</span>
                <img src={require("../../assets/lorry/axle-2l.png")} alt="" className="ts-ax"/>
                <img src={returnColor(lf.degree).url} alt="" className="ts-le"/>
                <img src={returnColor(lb.degree).url} alt="" className="ts-ri"/>
                <span className="ts-num-ri">{lang != 'zh'?lb.en_num:lb.number}</span>
                <span className="ts-num-le">{lang != 'zh'?lf.en_num:lf.number}</span>
            </div>
        )
    }

}

export default SingleTyre