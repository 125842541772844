import React, { Component } from 'react';
import intl from 'react-intl-universal';
import './App.scss';
import Login from "./views/login/Login"
import Reports from "./views/reports/List"
import Cormach from "./views/reports/cormach/Cormach"
import Cookies from 'universal-cookie';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Detail from "./views/reports/Detail";
import LorList from "./views/reports/lorry/LorList";
import LorDetail from "./views/reports/lorry/LorDetail";
const cookies = new Cookies();

class App extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props){
    super(props)
    this.state = {
      user:null,
      user_type:null,
    }
    this.changeLang = this.changeLang.bind(this)
  }

  changeLang() {
    const lang = intl.options.currentLocale
    const language = document.getElementById('language').value
    cookies.set('language',language)
    intl.options.currentLocale = cookies.get('language')
    // intl.options.currentLocale = lang==='zh'?'en':'zh'
    this.forceUpdate()
  }


  componentDidMount(){
    var pathname = window.location.pathname;
    if(pathname.search("email") != -1){
       return
    } else if(!cookies.get('tyrehub-pc')){
      this.props.history.push('/login')
      return
    }
    this.getUser()
  }

  getUser(){
    global.Api.getUser().then(res=>{
      if (res.data.code === 0) {
        let _data = res.data.data;
        this.setState({user:_data.user,user_type:_data.user_type});
        if(this.props.location.pathname === '/login'){
          this.props.history.push('/reports')
        }
      }
    })
  }

  render() {
    //语言选择器
    const LanguageSelect = () =>{
      return(
        <select id='language'  onChange={this.changeLang}
                style={{position:"absolute",right:"20px",top:"18px",zIndex:3,color:"blue",
                }}>
        <option>-{intl.get('language')}-</option>
        <option value='zh' >中文</option>
        <option value='en'>English</option>
        <option value='ma'>Melayu</option>
        <option value='ge'>Deutsch</option>
        <option value='fr'>Français</option>
        <option value='lt'>Italiano</option>
        <option value='sp'>Español</option>
        <option value='tw'>繁體</option>
        <option value='jp'>日本語</option>
        <option value='po'>Polska</option>
        <option value='por'>Português</option>
        <option value='nl'>Nederlands</option>
        </select>
      )
    };
    return ( 
      <Router>
        <LanguageSelect />
        <Route exact path="/" component={Login}/>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/reports" component={Reports}/>
        <Route exact path="/lorry" component={LorList}/>
        <Route exact path="/lordetail/:id" component={LorDetail}/>
        <Route exact path="/detail/:id" component={Detail}/>
        <Route exact path="/email/:uuid" component={Cormach}/>
      </Router>
    );
  }
}

export default App;
