import React, { Component } from 'react';
import intl from "react-intl-universal";
import "./css/Annotation.scss";
class Annotation extends Component{
    constructor(props) {
        super(props);
        this.state ={}
    }


    render() {
        return (
            <div className="annotation">
                <div className="ann-con">
                    <p><span className="n-dot" style={{backgroundColor:'green'}}></span><span className="c-text">{intl.get('buone')}</span></p>
                    <p><span className="n-dot" style={{backgroundColor:'yellow'}}></span><span className="c-text">{intl.get('lavoro')}</span></p>
                    <p><span  className="n-dot" style={{backgroundColor:'red'}}></span><span className="c-text">{intl.get('concordare')}</span></p>
                </div>
            </div>
        )
    }

}

export default Annotation;