export default {
  'login':'Einloggen',
  'input_name':'Bitte geben sie einen benutzernamen ein',
  'input_password':'Geben sie uns ihr passwort',
  'login_su':'Es erfolg',
  'role':'Rolle',
  'username':'Name des Benutzers',
  'password':'Passwort',

  'store':'Name des Geschäfts',
  'number':'Gerätenummer',
  'device_time':'Prüfzeit',
  'wear_co':'Tragezustand',
  'ecc_co':'Teilweise getragen',
  'plate_no':'Autokennzeichen',
  'cus_ph':'Mobiltelefonnummer',
  'car_type':'Autotyp',
  'operation':'Betätigen',
  'view':'Details anzeigen',
  'cus_email': 'Kunde E-Mail',
  'cus_name': 'Name des Kunden',


  'print':'ein Siegel aufdrücken',
  'store_name':'Laden',
  'address':'Ladenadresse',
  'model':'Autotyp',
  'mileage':'Kilometerstand',
  'device':'Prüfgerät',
  'remark':'Bemerkungen',
  'purpose':'Zweck',
  're':'Prüfvorschläge',
  'color_flag':'Farbidentifikation',
  'wear':'Gesamt verschlissen',
  'brake':'Erinnerung des aktuellen Bremswegs',
  'ecc':'ungleichmäßige Abnutzung',
  'lf':'L-F',
  'lr':'L-R',
  'rf':'R-F',
  'rr':'R-R',
  'untest':'Reifen nicht erkannt',
  'low':'Gut',
  'middle':'Kritisch',
  'high':'Sofort ersetzen',
  'avg':'Durchschnittlicher Wert',
  'spec':"Größe",
  'brand':'Marke',
  'ecc_avg':'Gleichmäßige Abnutzung bei Reifenprofil',
  'trauma':'Reifentrauma',
  'zc':'Normal',
  'image':'zh',
  'qr':'Scannen Sie den Code, um den Bericht anzuzeigen',
  'model_number':'HH Tire Tread Depth Detector',
  'purp_wash':'Autowäschee & -schönheit',
  'purp_alignment':'4-Rad-Ausrichtung',
  'purp_replace':'Reifenwechsel',
  'purp_maintain':'Wartung',
  'warn': 'erinnern：Dem aktuellen Abnutzungsstatus nach könnte der Bremsweg um',
  'trauma_normal':'Normal',
  'trauma_bulge':'Beule',
  'trauma_aging':'Alterung',

  'txt_lf_outworn':'Die Außenseite des linken Vorderreifens ist schlimmer als die Innenseite abgenutzt.',
  'txt_lf_innerworn':'Die Innenseite des linken Vorderreifens ist schlimmer als die Außenseite abgenutzt.',
  'txt_lf_bothsideworn':'Die beiden Seiten des linken Vorderreifens sind schlimmer als die Mitte abgenutzt.',
  'txt_lf_centreworn':'Die Mitte des linken Vorderreifens ist schlimmer als die beiden Seiten abgenutzt.',
  'txt_lb_outworn':'Die Außenseite des linken Hinterreifens ist schlimmer als die Innenseite abgenutzt.',
  'txt_lb_innerworn':'Die Innenseite des linken Hinterreifens ist schlimmer als die Außenseite abgenutzt.',
  'txt_lb_bothsideworn':'Die beiden Seiten des linken Hinterreifens sind schlimmer als die Mitte abgenutzt.',
  'txt_lb_centreworn':'Die Mitte des linken Hinterreifens ist schlimmer als die beiden Seiten abgenutzt.',
  'txt_rb_outworn':'Die Außenseite des rechten Vorderreifens ist schlimmer als die Innenseite abgenutzt.',
  'txt_rb_innerworn': 'Die Innenseite des rechten Vorderreifens ist schlimmer als die Außenseite abgenutzt.',
  'txt_rb_bothsideworn': 'Die beiden Seiten des rechten Vorderreifens ist schlimmer als die Mitte abgenutzt.',
  'txt_rb_centreworn': 'Die Mitte des rechten Vorderreifens ist schlimmer als die beiden Seiten abgenutzt.',
  'txt_rf_outworn': 'Die Außenseite des rechten Hinterreifens ist schlimmer als die Innenseite abgenutzt.',
  'txt_rf_innerworn':  'Die Innenseite des rechten Hinterreifens ist schlimmer als die Außenseite abgenutzt.',
  'txt_rf_bothsideworn': 'Die beiden Seiten des rechten Hinterreifens ist schlimmer als die Mitte abgenutzt.',
  'txt_rf_centreworn': 'Die Mitte des rechten Hinterreifens ist schlimmer als die beiden Seiten abgenutzt.' ,

  'txt_tire_need_replace':          'Abnutzungen beim Reifen, sofort wechseln',
  'txt_tire_boundary':              'Abnutzungen beim Reifen, im Grenzenstatus',
  'txt_alignment_chassis':          'Eine weitere 4-Rad-Ausrichtung oder eine Prüfung beim Fahrgestell wird vorgeschlagen',
  'txt_wear_uniform':               'Regelmäßige Abnutzung bei einzelnem Reifen',
  'txt_do_further_check':           'Bitte führen Sie eine weitere Prüfung durch',
  'txt_uneven_check':                'Unregelmäßige Abnutzung, eine weitere Prüfung wird vorgeschlagen',
  'txt_tire_rotation':              'Reifenrotation wird vorgeschlagen',
  'txt_wear_normal':                'Normale Abnutzung bei Reifenprofil',
  'txt_tire':                       'Reifen',

  'time':'Zeit auswählen',
  'today':'Heute',
  'yesterday':'Gestern',
  'week':'Diese Woche',
  'month':'Dieser Monat',
  'last_month':'Letzter Monat',
  'year':'Dieses Jahr',
  'language':'Sprache wählen',
  'zh':'Chinesisch',
  'en':'Englisch',
  'ma':'Malaiische Sprache',
  'ge':'Deutsch n',
  'fr':'Französisch n',
  'lt':'italienisch ',
  'sp':'Spanische',
  'tw':'Langzeichen',
  'jp':'Japanisch',
  'po':'Poland',
  'por':'Portugiesisch',
  'download':'Herunterladen',
  'advise': 'Vorschlagen：Der Reifen hat teilweise Abnutzung und eine weitere Inspektion wird empfohlen',

  'txt_tire_age_5': 'Bei reifenspuren, die bereits mehr als 5 jahre alt sind, empfiehlt es sich eine gründliche inspektion.',
  'txt_tire_age_3': 'Bei reifenspuren, die bereits mehr als drei jahre alt sind, empfiehlt es sich eine gründliche inspektion;',
  'txt_tire_age_ok': 'Die reifen brauchen geläutert zu sein;',
  'txt_tire_trauma_check': 'Die Reifenoberfläche ist beschädigt, weitere Inspektion wird empfohlen;',
  'txt_install_abnormal_check': 'Reifenmontage ist abnormal, weitere Inspektion wird empfohlen.',
  'txt_tire_appearance_ok': 'Gutes Reifenbild;',
  'txt_pinnate_check':'Es gibt Federverschleiß auf dem Reifen. Es wird empfohlen, die Fahrgestellteile weiter zu prüfen.',
  'trauma_lacerate': 'Lacerat',
  'trauma_dropblock': 'Block fallenlassen',
  'trauma_puncture': 'Punktion',
  'trauma_scratch': 'Kratzen',
  'trauma_pinnate': 'Verschleiß von Pinnaten',
  'txt_tire_age': 'Reifen eine',
  'txt_tire appearance': 'Reifen aus',
  'tire_installed': 'Reifen installiert',
  'abnormal': 'Rückwärtsgang innen und außen',
  'endurance': 'Die ausweichmeilen der reifen werden berechnet',
  'endurance-warn': 'Verbleibende Ausdauer ist {number}km',
  'endurance-zero': 'Der Reifen hat die Grenze erreicht, ersetzen Sie ihn bitte sofort',
  'estimated_value': 'Geschätzter Wert',
  'abrasion': 'Abrieb',
  'remanent': 'Die reifen brauchen noch {days} tage',
  'useful_life': 'Die reifen werden mit gefrieren berechnet',
   'dot_explain': 'DOT：Zeit für reifen!',
  'unit_km': 'Unternehmen：km',
  'unit_year': 'Unternehmen：jahr',
  'dot_no': 'Keine Herstellungszeit',
  'made_time': 'Produktionszeit: {year} ({year} Jahr {week} Woche)',
  'exceed_five': 'Die Lebensdauer der Reifen hat fünf Jahre überschritten, bitte ersetzen Sie sie sofort',
  'inspector': 'Techniker',
  'txt_install_check': 'Inspektion der Anlage',
  'txt_install_ok': 'Normale Installation',
  'txt_pinnate_wear': 'Verschleiß von Pinnaten',







  'LFtxt_tire_need_replace': '[L-F]Abnutzungen beim Reifen, sofort wechseln',
  'RFtxt_tire_need_replace': '[R-F]Abnutzungen beim Reifen, sofort wechseln',
  'LBtxt_tire_need_replace': '[L-R]Abnutzungen beim Reifen, sofort wechseln',
  'RBtxt_tire_need_replace': '[R-R]Abnutzungen beim Reifen, sofort wechseln',
  'FFtxt_tire_need_replace': '[F-F]Abnutzungen beim Reifen, sofort wechseln',
  'BBtxt_tire_need_replace': '[B-B]Abnutzungen beim Reifen, sofort wechseln',


  'txt_pattern_wear': 'Abnormale Abnutzung',
  'txt_uneven_center': 'Abnormale Abnutzung in der Mitte des Reifens. Es wird empfohlen, den Reifendruck zu überprüfen',
  'LFtxt_uneven_center': '[L-F]Abnormale Abnutzung in der Mitte des Reifens. Es wird empfohlen, den Reifendruck zu überprüfen',
  'RFtxt_uneven_center': '[R-F]Abnormale Abnutzung in der Mitte des Reifens. Es wird empfohlen, den Reifendruck zu überprüfen',
  'LBtxt_uneven_center': '[L-R]Abnormale Abnutzung in der Mitte des Reifens. Es wird empfohlen, den Reifendruck zu überprüfen',
  'RBtxt_uneven_center': '[R-R]Abnormale Abnutzung in der Mitte des Reifens. Es wird empfohlen, den Reifendruck zu überprüfen',
  'FFtxt_uneven_center': '[F-F]Abnormale Abnutzung in der Mitte des Reifens. Es wird empfohlen, den Reifendruck zu überprüfen',
  'BBtxt_uneven_center': '[B-B]Abnormale Abnutzung in der Mitte des Reifens. Es wird empfohlen, den Reifendruck zu überprüfen',

  'txt_uneven_out': 'Die äußere Seite des Reifens wird abnormal getragen. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen',
  'LFtxt_uneven_out': '[L-F]Die äußere Seite des Reifens wird abnormal getragen. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen',
  'RFtxt_uneven_out': '[R-F]Die äußere Seite des Reifens wird abnormal getragen. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen',
  'LBtxt_uneven_out': '[L-R]Die äußere Seite des Reifens wird abnormal getragen. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen',
  'RBtxt_uneven_out': '[R-R]Die äußere Seite des Reifens wird abnormal getragen. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen',
  'FFtxt_uneven_out': '[F-F]Die äußere Seite des Reifens wird abnormal getragen. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen',
  'BBtxt_uneven_out': '[B-B]Die äußere Seite des Reifens wird abnormal getragen. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen',

  'LFtxt_pinnate_check':'[L-F]Es gibt Federverschleiß auf dem Reifen. Es wird empfohlen, die Fahrgestellteile weiter zu prüfen.',
  'RFtxt_pinnate_check':'[R-F]Es gibt Federverschleiß auf dem Reifen. Es wird empfohlen, die Fahrgestellteile weiter zu prüfen.',
  'LBtxt_pinnate_check':'[L-R]Es gibt Federverschleiß auf dem Reifen. Es wird empfohlen, die Fahrgestellteile weiter zu prüfen.',
  'RBtxt_pinnate_check':'[R-R]Es gibt Federverschleiß auf dem Reifen. Es wird empfohlen, die Fahrgestellteile weiter zu prüfen.',
  'FFtxt_pinnate_check':'[F-F]Es gibt Federverschleiß auf dem Reifen. Es wird empfohlen, die Fahrgestellteile weiter zu prüfen.',
  'BBtxt_pinnate_check':'[B-B]Es gibt Federverschleiß auf dem Reifen. Es wird empfohlen, die Fahrgestellteile weiter zu prüfen.',

  'txt_uneven_inside': 'Die innere Seite des Reifens wird abnormal getragen. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen',
  'LFtxt_uneven_inside': '[L-F]Die innere Seite des Reifens wird abnormal getragen. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen',
  'RFtxt_uneven_inside': '[R-F]Die innere Seite des Reifens wird abnormal getragen. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen',
  'LBtxt_uneven_inside': '[L-R]Die innere Seite des Reifens wird abnormal getragen. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen',
  'RBtxt_uneven_inside': '[R-R]Die innere Seite des Reifens wird abnormal getragen. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen',
  'FFtxt_uneven_inside': '[F-F]Die innere Seite des Reifens wird abnormal getragen. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen',
  'BBtxt_uneven_inside': '[B-B]Die innere Seite des Reifens wird abnormal getragen. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen',

  'FFtxt_uneven_check': '[F-F]Unregelmäßige Abnutzung, eine weitere Prüfung wird vorgeschlagen',
  'BBtxt_uneven_check': '[B-B]Unregelmäßige Abnutzung, eine weitere Prüfung wird vorgeschlagen',


  'LFtxt_tire_age_5': '[L-F]Bei reifenspuren, die bereits mehr als 5 jahre alt sind, empfiehlt es sich eine gründliche inspektion.',
  'RFtxt_tire_age_5': '[R-F]Bei reifenspuren, die bereits mehr als 5 jahre alt sind, empfiehlt es sich eine gründliche inspektion.',
  'LBtxt_tire_age_5': '[L-R]Bei reifenspuren, die bereits mehr als 5 jahre alt sind, empfiehlt es sich eine gründliche inspektion.',
  'RBtxt_tire_age_5': '[R-R]Bei reifenspuren, die bereits mehr als 5 jahre alt sind, empfiehlt es sich eine gründliche inspektion.',
  'FFtxt_tire_age_5': '[F-F]Bei reifenspuren, die bereits mehr als 5 jahre alt sind, empfiehlt es sich eine gründliche inspektion.',
  'BBtxt_tire_age_5':  '[B-B]Bei reifenspuren, die bereits mehr als 5 jahre alt sind, empfiehlt es sich eine gründliche inspektion.',

  'txt_appearance_check':'Das Reifenbild ist abnormal, weitere Untersuchungen werden empfohlen.',
  'LFtxt_appearance_check':'[L-F]Das Reifenbild ist abnormal, weitere Untersuchungen werden empfohlen.',
  'RFtxt_appearance_check':'[R-F]Das Reifenbild ist abnormal, weitere Untersuchungen werden empfohlen.',
  'LBtxt_appearance_check':'[L-R]Das Reifenbild ist abnormal, weitere Untersuchungen werden empfohlen.',
  'RBtxt_appearance_check':'[R-R]Das Reifenbild ist abnormal, weitere Untersuchungen werden empfohlen.',
  'FFtxt_appearance_check':'[F-F]Das Reifenbild ist abnormal, weitere Untersuchungen werden empfohlen.',
  'BBtxt_appearance_check':'[B-B]Das Reifenbild ist abnormal, weitere Untersuchungen werden empfohlen.',


  'LFtxt_install_abnormal_check':'[L-F]Reifenmontage ist abnormal, weitere Inspektion wird empfohlen.',
  'RFtxt_install_abnormal_check':'[R-F]Reifenmontage ist abnormal, weitere Inspektion wird empfohlen.',
  'LBtxt_install_abnormal_check':'[L-R]Reifenmontage ist abnormal, weitere Inspektion wird empfohlen.',
  'RBtxt_install_abnormal_check':'[R-R]Reifenmontage ist abnormal, weitere Inspektion wird empfohlen.',
  'FFtxt_install_abnormal_check':'[F-F]Reifenmontage ist abnormal, weitere Inspektion wird empfohlen.',
  'BBtxt_install_abnormal_check':'[B-B]Reifenmontage ist abnormal, weitere Inspektion wird empfohlen.',

  'txt_uneven_bothside': 'Abnormale Abnutzung auf beiden Seiten des Reifens. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen.',
  'LFtxt_uneven_bothside': '[L-F]Abnormale Abnutzung auf beiden Seiten des Reifens. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen.',
  'RFtxt_uneven_bothside': '[R-F]Abnormale Abnutzung auf beiden Seiten des Reifens. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen.',
  'LBtxt_uneven_bothside': '[L-R]Abnormale Abnutzung auf beiden Seiten des Reifens. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen.',
  'RBtxt_uneven_bothside': '[R-R]Abnormale Abnutzung auf beiden Seiten des Reifens. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen.',
  'FFtxt_uneven_bothside': '[F-F]Abnormale Abnutzung auf beiden Seiten des Reifens. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen.',
  'BBtxt_uneven_bothside': '[B-B]Abnormale Abnutzung auf beiden Seiten des Reifens. Es wird empfohlen, die Vierrad-Ausrichtung und Reifendruck zu überprüfen.',


  'txt_wear_lookup': 'Bitte überprüfen Sie Reifenverschleiß',
  'LFtxt_wear_lookup': '[L-F]Bitte überprüfen Sie Reifenverschleiß',
  'RFtxt_wear_lookup': '[R-F]Bitte überprüfen Sie Reifenverschleiß',
  'LBtxt_wear_lookup': '[L-B]Bitte überprüfen Sie Reifenverschleiß',
  'RBtxt_wear_lookup': '[R-B]Bitte überprüfen Sie Reifenverschleiß',
  'FFtxt_wear_lookup': '[F-F]Bitte überprüfen Sie Reifenverschleiß',
  'BBtxt_wear_lookup': '[B-B]Bitte überprüfen Sie Reifenverschleiß',

  'txt_appearance_lookup': 'Bitte überprüfen Sie das Aussehen der Reifen',
  'LFtxt_appearance_lookup': '[L-F]Bitte überprüfen Sie das Aussehen der Reifen',
  'RFtxt_appearance_lookup': '[R-F]Bitte überprüfen Sie das Aussehen der Reifen',
  'LBtxt_appearance_lookup': '[L-B]Bitte überprüfen Sie das Aussehen der Reifen',
  'RBtxt_appearance_lookup': '[R-B]Bitte überprüfen Sie das Aussehen der Reifen',
  'FFtxt_appearance_lookup': '[F-F]Bitte überprüfen Sie das Aussehen der Reifen',
  'BBtxt_appearance_lookup': '[B-B]Bitte überprüfen Sie das Aussehen der Reifen',

  'txt_serious_trauma_check': 'Der Reifen hat eine schwere Verletzung. Es wird empfohlen, den Reifen zu ersetzen',
  'LFtxt_serious_trauma_check': '[L-F]Der Reifen hat eine schwere Verletzung. Es wird empfohlen, den Reifen zu ersetzen',
  'RFtxt_serious_trauma_check': '[R-F]Der Reifen hat eine schwere Verletzung. Es wird empfohlen, den Reifen zu ersetzen',
  'LBtxt_serious_trauma_check': '[L-B]Der Reifen hat eine schwere Verletzung. Es wird empfohlen, den Reifen zu ersetzen',
  'RBtxt_serious_trauma_check': '[R-B]Der Reifen hat eine schwere Verletzung. Es wird empfohlen, den Reifen zu ersetzen',
  'FFtxt_serious_trauma_check': '[F-F]Der Reifen hat eine schwere Verletzung. Es wird empfohlen, den Reifen zu ersetzen',
  'BBtxt_serious_trauma_check': '[B-B]Der Reifen hat eine schwere Verletzung. Es wird empfohlen, den Reifen zu ersetzen',

  'purp_tireservice':'Service für Reifen',
  'purp_vehicle_inspection':'Inspektion des Fahrzeugs',
  'merchant': 'Dateien',
  'manager': 'Ladenbesitzer',
  'assistant': 'Schreiber',

  'txt_chassis_check': 'Inspektion der Chassis',
  'txt_chassis_ok': 'Das Chassis ist in gutem Zustand',
  'LFtxt_chassis_ok': '[L-F]Das Chassis ist in gutem Zustand',
  'RFtxt_chassis_ok': '[R-F]Das Chassis ist in gutem Zustand',
  'LBtxt_chassis_ok': '[L-B]Das Chassis ist in gutem Zustand',
  'RBtxt_chassis_ok': '[R-R]Das Chassis ist in gutem Zustand',
  'FFtxt_chassis_ok': '[F-F]Das Chassis ist in gutem Zustand',
  'BBtxt_chassis_ok': '[B-B]Das Chassis ist in gutem Zustand',
  'txt_chassis_oil': 'Der Stoßdämpfer hat eine Ölleckage. Es wird empfohlen, ihn bei Bedarf weiter zu bestätigen und zu ersetzen.',
  'LFtxt_chassis_oil': '[L-F]Der Stoßdämpfer hat eine Ölleckage. Es wird empfohlen, ihn bei Bedarf weiter zu bestätigen und zu ersetzen.',
  'RFtxt_chassis_oil': '[R-F]Der Stoßdämpfer hat eine Ölleckage. Es wird empfohlen, ihn bei Bedarf weiter zu bestätigen und zu ersetzen.',
  'LBtxt_chassis_oil': '[L-B]Der Stoßdämpfer hat eine Ölleckage. Es wird empfohlen, ihn bei Bedarf weiter zu bestätigen und zu ersetzen.',
  'RBtxt_chassis_oil': '[R-R]Der Stoßdämpfer hat eine Ölleckage. Es wird empfohlen, ihn bei Bedarf weiter zu bestätigen und zu ersetzen.',
  'FFtxt_chassis_oil': '[F-F]Der Stoßdämpfer hat eine Ölleckage. Es wird empfohlen, ihn bei Bedarf weiter zu bestätigen und zu ersetzen.',
  'BBtxt_chassis_oil': '[B-B]Der Stoßdämpfer hat eine Ölleckage. Es wird empfohlen, ihn bei Bedarf weiter zu bestätigen und zu ersetzen.',
  'txt_chassis_aging': 'Der Gummi des Wellenkopfes altert, so dass es empfehlenswert ist, ihn zu ersetzen.',
   'LFtxt_chassis_aging': '[L-F]Der Gummi des Wellenkopfes altert, so dass es empfehlenswert ist, ihn zu ersetzen.',
  'RFtxt_chassis_aging': '[R-F]Der Gummi des Wellenkopfes altert, so dass es empfehlenswert ist, ihn zu ersetzen.',
  'LBtxt_chassis_aging': '[L-B]Der Gummi des Wellenkopfes altert, so dass es empfehlenswert ist, ihn zu ersetzen.',
  'RBtxt_chassis_aging': '[R-R]Der Gummi des Wellenkopfes altert, so dass es empfehlenswert ist, ihn zu ersetzen.',
  'FFtxt_chassis_aging': '[F-F]Der Gummi des Wellenkopfes altert, so dass es empfehlenswert ist, ihn zu ersetzen.',
  'BBtxt_chassis_aging': '[B-B]Der Gummi des Wellenkopfes altert, so dass es empfehlenswert ist, ihn zu ersetzen.',
   'awd': 'App Download',
   'depth': 'Trench Depth',
    'usura': 'Eccentric Wear',
    'usura-l': 'The pattern is worn evenly',
    'usura-m': 'Slight eccentric wear',
    'usura-h': 'Severe eccentric wear',

    'engine-hood': 'ENGINE BONNET',
    'change-control': 'CHANGE CONTROL',
    'subtotal': 'UNDERBODY',

    'replace': 'REPLACE',
    'vedi': 'READ NOTE',
    'ok': 'OK',

    'liquid': 'WASHING LIQUID',
    'level-control': 'Level control',
    'air': 'AIR CONDITIONER',
    'last-reload': 'Last recharge',
    'absorber': 'SHOCK ABSORBER',
    'check-loss': 'Check of visible leaks',
    'fluid': 'RADIATOR FLUID',
    'lighting': 'LIGHTS',
    'verification': 'Opeation check',
    'disc': 'BRAKE DISCS',
    'condition': 'Wearing',
    'oil': 'MOTOR OI',
    'wiper': 'WIPERS',
    'pads': 'BRAKE PADS',
    'brake-fluid': 'BRAKE FLUID',
    'quality-control': 'Quality check',
    'battery': 'BATTERY',
    'multimetro': 'Multimetro test',
    'control-quality': 'QUALITY CHECK',
    'tightening': 'Dynamometer tightening',
    'buone': 'ok',
    'lavoro': 'Work scheduling',
    'concordare': 'Allow substitution',
     'season_summer': 'Ihre sommerreifen',
     'season_winter': 'Die winterreifen.',

    'com':'Commercial',
    'pass':'Passenger',


}
