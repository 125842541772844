import React, {Component} from "react";
import "./css/CormachWearCar.scss"
class CormachWearCar extends Component{
    constructor(props) {
        super(props);
        this.state ={}
    }

    render() {
        return(
            <div className="w-car-con">
                <img className="w-car-cen" src={require("../assets/car.png")} alt=""/>
            </div>
        )
    }

}

export default CormachWearCar;