import React, {Component} from "react";
import "../css/Lorry/Content.scss"
import CarSketch from "./CarSketch"
import SingleTire from "./SingleTire";
import DoubleTire from "./DoubleTire";
import intl from "react-intl-universal";

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    };

    render() {
        let {tireData, genre, unit} = this.props.data;
        return (
            <div className="re-content">
                <div className="co-title"><span className="ic"></span><span>{intl.get('wear')}</span></div>
                <div style={{position: 'relative'}}>
                    <img
                        style={{
                            width: 750,
                            marginTop: 10,
                            marginLeft:10,
                            height: 40,
                        }}
                        src={require("../../assets/wear-lor-lev.png")}
                    />
                    <div style={{
                        position: 'absolute',
                        top: 10,
                        left: 50,
                        right: 50,
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <div style={{fontSize: 22, color: 'white', textAlign: 'center'}}>
                            <p>{intl.get('low')}</p>
                        </div>
                        <div style={{fontSize: 22, color: 'white', textAlign: 'center'}}>
                            <p>{intl.get('high')}</p>
                        </div>
                    </div>
                </div>


                <div className='cm'>
                    <CarSketch data={{type: genre, tires: tireData}}/>
                </div>
                <div>
                    {tireData.map((item) => (
                        item.double === false ? <SingleTire data={{item:item, unit: unit}}/> : <DoubleTire data={{item:item, unit: unit}}/>
                    ))}
                </div>
                <div style={{height: 50}}></div>
            </div>
        )
    }

}

export default Content