import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// axios.defaults.baseURL='http://dashboard.iptirescanner.com/';
// request 拦截器
axios.interceptors.request.use(
  config => {
    if (cookies.get('tyrehub-pc')) { // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.Authorization = cookies.get('tyrehub-pc')
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
);

// response 拦截器
axios.interceptors.response.use(
  response => {
    // token过期
    if (response.data.code === 1101) {
      cookies.remove('tyrehub-pc');
      window.location.reload()
    }
    return response
  },
  error => {
    return Promise.reject(error) // 返回接口返回的错误信息
  }
);

const Api = {
  /**
   * 登录
   * @param {*} params
   */
  login(params = {}){
    return axios.put('/api/user/login/',params)
  },

  /**
   * 获取当前用户详情
   * @param {*} params
   */
  getUser(params){
    return axios.get('/api/current_user/',{params:params})
  },

  /**
   * 列出检测报告
   * @param {*} params
   */
  getReports(params) {
    return axios.get('/api/reports/', {params:params})
  },
   /**
   * 下载检测检测报告
   * @param {*} params
   */
  downloadReports(params) {
    return axios.get('/api/reports/download/', {params:params})
  },

  /**
   * 获取检测报告详情
   * @param {*} reportId
   * @param {*} params
   */
  getReportsDetail(reportId, params) {
    return axios.get(`/api/reports/${reportId}/`, {params:params})
  },

      /**
   * 获取检测报告详情
   * @param {*} reportUuid
   * @param {*} params
   */
 getReportsEmail(reportUuid, params) {
    return axios.get(`/api/reports/email/${reportUuid}/`, {params:params})
  },
    /**
   * 获取检测报告详情
   * @param {*} reportUuid
   * @param {*} params
   */
 getReportDetail(reportUuid, params) {
    return axios.get(`/api/lorry_reports/${reportUuid}/`, {params:params})
  },


  /**
   * 下载APP
   * @param {*} params
   */
  getApp(params) {
    return axios.get('/api/app/', {params:params})
  },

      /**
   * 获取大车报告列表
   * @param {*} params
   */
  getLorList(params) {
    return axios.get('/api/lorry_reports/list/', {params:params})
  },

          /**
   * 获取大车报告列表
   * @param {*} params
   */
  getLorDetail(Uuid, params) {
    return axios.get(`/api/lorry_reports/${Uuid}/`, {params:params})
  }
};

Object.assign(global,{Api});
